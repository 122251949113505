/** @module global/useRestaurants */
import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../components/authentication/AuthContext';
import { getRestaurants } from './request/restaurant';
import SimpleCache from './Cache';

const cache = new SimpleCache();

// XXX: do we want a preload function?

/**
 * Hook that returns all restaurants. Caches data to prevent unnecessary requests.
 * @returns {[Function, types.Restaurant]} A function to reload the menu, the menu itself, and the categories of the menu
 */
function useRestaurants() {
  const authCtx = useContext(AuthContext);

  const [restaurants, setRestaurants] = useState([]);

  const reload = async () => {
    if (!authCtx.tokens) return;

    const response = await getRestaurants(authCtx.tokens.idToken);
    setRestaurants(response);

    cache.store('restaurants', response);
  };

  useEffect(() => {
    if (!cache.has('restaurants')) {
      reload();
    } else {
      setRestaurants(cache.retrieve('restaurants'));
    }
  }, []);

  return [reload, restaurants];
}

export default useRestaurants;
