import React, { useState } from 'react';
import { IonButton, IonContent, IonHeader, IonLabel, IonPage, useIonRouter } from '@ionic/react';
import '../../styles/authentication/ResetPassword.scss';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [step, setStep] = useState('email');
  const [message, setMessage] = useState('');

  const router = useIonRouter();

  const resetForm = () => {
    setEmail('');
    setCode('');
    setNewPassword('');
    setStep('email');
    setMessage('');
  };

  const navigateToSignIn = () => {
    router.push('/signin');
    resetForm();
  };

  const apiUrl = 'https://vhx3rygae9.execute-api.us-east-1.amazonaws.com/dev/auth';

  const postToApi = async body => {
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error('Information you provided is incorrect. Try again.');
      }

      return await response.json();
    } catch (error) {
      console.error('Error during API call', error);
      throw error;
    }
  };

  const handleResetPassword = async e => {
    e.preventDefault();
    setMessage('');
    if (step === 'reset' && newPassword !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }
    try {
      if (step === 'email') {
        await postToApi({ action: 'forgot_password', email });
        setMessage('A reset code has been sent to your email. Please be sure to check your spam filter.');
        setStep('reset');
      } else if (step === 'reset') {
        await postToApi({ action: 'confirm_forgot_password', email, code, password: newPassword });
        setMessage('Your password has been reset successfully.');
        setStep('confirm');
      }
    } catch (error) {
      setMessage(error.message);
    }
  };

  return (
    <IonPage className="ion-page">
      <IonContent>
        <div className="reset-password-content">
          <div className="logo-icon" />
          <IonHeader className="reset-password-header">Reset Password</IonHeader>
          <div className="back-to-homepage" role="button" tabIndex={0} onClick={navigateToSignIn} />
          <form className="form-content" onSubmit={handleResetPassword}>
            {step === 'email' && (
              <>
                <IonLabel className="label">Email</IonLabel>
                <input
                  className="data-input"
                  type="email"
                  placeholder="Johndoe@email.com"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <IonButton className="next-button" type="submit">
                  Next
                </IonButton>
              </>
            )}

            {step === 'reset' && (
              <>
                <IonLabel className="label">Code</IonLabel>
                <input
                  className="data-input"
                  type="text"
                  placeholder="Enter the code"
                  value={code}
                  onChange={e => setCode(e.target.value)}
                />
                <IonLabel className="label">Password</IonLabel>
                <input
                  className="data-input"
                  type="password"
                  placeholder="**********"
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                />
                <IonLabel className="label">Confirm password</IonLabel>
                <input
                  className="data-input"
                  type="password"
                  placeholder="**********"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                />
                <IonButton className="next-button" type="submit">
                  Confirm
                </IonButton>
              </>
            )}

            {step === 'confirm' && <p>{message}</p>}
          </form>
          {message && step !== 'confirm' && <p>{message}</p>}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
