import React from 'react';
import { IonRippleEffect } from '@ionic/react';

const FoodFightButton = ({ time, onClick }) => {
  return (
    <button className="orange-box" type="button" onClick={onClick}>
      <div className="glove-image" />
      <div className="time">{time}</div>
      <IonRippleEffect />
    </button>
  );
};

export default FoodFightButton;
