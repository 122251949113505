import React, { createContext, useReducer, useMemo } from 'react';

const initialState = {
  selectedRestaurant: null,
  userBets: [],
  // ... add other states as necessary
  triggerGetLiveBets: false,
  // Friends list state to avoid creating a new context
  triggerGetFriendsList: false,
  // Profile state to avoid creating a new context
  triggerGetCachedProfile: false,
};

const MatchmakingContext = createContext(initialState);

const matchmakingReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_RESTAURANT':
      return {
        ...state,
        selectedRestaurant: action.payload,
      };
    case 'SET_USER_BETS':
      return {
        ...state,
        userBets: action.payload,
      };
    case 'SET_CHALLENGER':
      return {
        ...state,
        challenger: action.payload,
      };
    case 'CLEAR_CHALLENGER':
      return { ...state, challenger: null };
    case 'TRIGGER_GET_LIVE_BETS':
      return {
        ...state,
        triggerGetLiveBets: !state.triggerGetLiveBets,
      };
    // ... other actions
    case 'TRIGGER_GET_FRIENDS_LIST':
      return { ...state, triggerGetFriendsList: !state.triggerGetFriendsList };
    case 'TRIGGER_GET_CACHED_PROFILE':
      return { ...state, triggerGetCachedProfile: !state.triggerGetCachedProfile };
    default:
      return state;
  }
};

export const MatchmakingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(matchmakingReducer, initialState);

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return <MatchmakingContext.Provider value={contextValue}>{children}</MatchmakingContext.Provider>;
};

export default MatchmakingContext;
