/** @module global/request/restaurant */
import RequestUtility from './request';

const SERVICE_URL = 'https://j3kkhlv7s2.execute-api.us-east-1.amazonaws.com/default';
const util = new RequestUtility(SERVICE_URL);
const ENDPOINT = 'CarouselImages';
const FOLDER_NAME = 'CarouselImages';

function parseDoc(doc) {
  const keys = doc.querySelectorAll('Key');
  return keys;
}

async function getEntries(bearerToken) {
  const orderingsPromise = util.authorizedRequest(`${ENDPOINT}/ENTRIES_ORDERING.json`, {
    method: 'GET',
    bearerToken,
  });
  const response = await util.authorizedRequestXml(ENDPOINT, {
    method: 'GET',
    bearerToken,
  });
  const keys = Array.from(parseDoc(response));
  const jsonDict = {};
  keys.forEach(key => {
    const path = key.textContent;
    const dotSeparatedPath = path.split('.');
    const extension = dotSeparatedPath[dotSeparatedPath.length - 1];
    if (extension.toLowerCase() !== 'json') {
      return;
    }

    const slashSeparatedPath = path.split('/');
    if (slashSeparatedPath[0] !== FOLDER_NAME || slashSeparatedPath[1] !== 'Live') {
      return;
    }

    const endpointSuffix = [...slashSeparatedPath].splice(1, slashSeparatedPath.length).join('%2F');
    const name = slashSeparatedPath.splice(2, slashSeparatedPath.length).join('/');
    jsonDict[name] = util.authorizedRequest(`${ENDPOINT}/${endpointSuffix}`, {
      method: 'GET',
      bearerToken,
    });
  });
  const orderingsValue = await orderingsPromise;
  const orderedPromises = [];
  orderingsValue.forEach(entryName => {
    const promise = jsonDict[entryName];
    if (!promise) {
      return;
    }
    orderedPromises.push(promise);
  });
  const orderedJsonEntries = await Promise.all(orderedPromises);
  return orderedJsonEntries;
}

export default getEntries;
