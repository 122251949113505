import React, { useEffect, forwardRef } from 'react';
import { IonButton, IonModal, IonIcon, IonButtons, IonToolbar } from '@ionic/react';
import { closeCircle } from 'ionicons/icons';
import '../../../styles/fight/challenger/MatchMakingModal.scss';

/**
 * Modal for the "Post to Public" functionality.
 * @param {Object} props
 * @param {React.MutableRefObject} ref A ref to the match-challenger modal component.
 * @returns
 */
const MatchMakingModal = (props, ref) => {
  const { footerHeight } = props;
  useEffect(() => {
    // eslint-disable-next-line react/destructuring-assignment
    if (ref?.current && ref.current.style.bottom !== '0px') {
      // eslint-disable-next-line react/destructuring-assignment
      ref.current.style.bottom = `${footerHeight}px`;
    }
  }, [ref]);

  const handleCloseModal = () => {
    // eslint-disable-next-line react/destructuring-assignment
    if (ref.current) {
      // eslint-disable-next-line react/destructuring-assignment
      ref.current.dismiss();
    }
  };

  return (
    <IonModal ref={ref} className="match-modal" initialBreakpoint={1} breakpoints={[0, 1]}>
      <IonToolbar>
        <IonButtons slot="end">
          <IonButton onClick={handleCloseModal}>
            <IonIcon icon={closeCircle} slot="icon-only" />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <div className="match-modal-content">
        <h1>Post to Public</h1>
        <p>You are posting to the public board.</p>
        <p>You will be notified when a FoodFighter accepts!</p>
      </div>
    </IonModal>
  );
};

export default forwardRef(MatchMakingModal);
