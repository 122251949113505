import { IonButton, IonCard } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { AuthContext } from '../authentication/AuthContext';
import MatchmakingContext from '../../global/MatchmakingContext';
import { addFriend, removeFriend } from '../../global/request/user';

import profilePicPlaceholder from '../../assets/profiles/foodfight_profile_baseball.png';

import '../../styles/firendProfile/friendsListItem.scss';
import UnfollowModal from './UnfollowModal';

const FriendsListItem = ({ userId, username, imgSrc, isFollowingInit }) => {
  const authCtx = useContext(AuthContext);
  const bearerToken = authCtx.tokens.idToken;
  const { state, dispatch } = useContext(MatchmakingContext);
  const history = useHistory();

  const [isFollowing, setIsFollowing] = useState(isFollowingInit);
  const [isFollowingChanging, setIsFollowingChanging] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleProfileClick = () => {
    history.push({ pathname: `/app/friendprofile/${userId}` });
  };

  const unfollow = async () => {
    setIsFollowingChanging(true);
    try {
      await removeFriend(bearerToken, userId);
      setIsFollowing(false);
      dispatch({ type: 'TRIGGER_GET_FRIENDS_LIST' });
    } catch (error) {
      console.error(error);
    }
    setIsFollowingChanging(false);
  };

  const toggleIsFollowing = async () => {
    setIsFollowingChanging(true);
    if (isFollowing) {
      try {
        setIsModalOpen(true);
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        console.log(await addFriend(bearerToken, userId));
        setIsFollowing(true);
        dispatch({ type: 'TRIGGER_GET_FRIENDS_LIST' });
      } catch (error) {
        console.error(error);
      }
    }
    setIsFollowingChanging(false);
  };

  useEffect(() => {
    setIsFollowing(isFollowingInit);
  }, [isFollowingInit, state.friendsList]);

  return (
    <IonCard className="friends-list-item">
      <button className="button-wrapper" type="button" onClick={handleProfileClick}>
        <img src={imgSrc || profilePicPlaceholder} alt="profile-pic" className="profile-pic" />
        <span className="username">{username}</span>
      </button>
      {isFollowing !== null && (
        <IonButton
          className={`follow-button ion-no-margin ${`is-following-${isFollowing}`}`}
          size="small"
          onClick={toggleIsFollowing}
          disabled={isFollowingChanging}
        >
          {isFollowing ? 'Following' : 'Follow'}
        </IonButton>
      )}
      <UnfollowModal
        isOpen={isModalOpen}
        handleCloseModal={() => setIsModalOpen(false)}
        handleUnfollowClick={unfollow}
      />
    </IonCard>
  );
};

export default FriendsListItem;
