import React, { useContext, useEffect, useState } from 'react';
import { IonSearchbar } from '@ionic/react';
import { allUsers, getFriendsProfile } from '../../global/request/user';

import '../../styles/firendProfile/friendsList.scss';
import { AuthContext } from '../authentication/AuthContext';
import MatchmakingContext from '../../global/MatchmakingContext';
import FriendsListItem from './FriendsListItem';
import useProfile from '../../global/useProfile';
import { FriendsListItemLoader } from '../skeletalLoaders/SkeletalLoaders';
import generateUniqueKey from '../../UniqueKeyGenerator/UniqueKeyGenerator';

// A lot of logic and props can be simplified if a getFriends route is added to backend
/**
 * List of a given user's friends with follow buttons
 * All props are optional, but can be passed to avoid duplicate api calls between parent and this component
 * @param {Object} props
 * @param {Object[]} props.friendsData All necessary data (id, username, icon) for each friend to be displayed. Component will make no api calls if this prop is given.
 * @param {string[]} props.friendsIds IDs of each friend to be displayed. Avoids getFriendsProfile api call if prop is given.
 * @param {Object[]} props.allUsersData Necessary data (id, username, icon) of each user in the database. Avoids allUsers api call if prop is given.
 * @param {string} props.userId ID of user to get the friends list of. Required if friendsData and friendsIds are not given.
 * @param {boolean} props.triggerFollowButtonsRefresh Triggers follow buttons to recalculate value.
 * @param {string[]} props.selfFriendsIds IDs of the current user's friends. Avoids getProfile api call if prop is given.
 * @returns
 */
const FriendsList = ({
  friendsData,
  friendsIds,
  allUsersData,
  userId,
  triggerFollowButtonsRefresh,
  selfFriendsIds,
}) => {
  const authCtx = useContext(AuthContext);
  const bearerToken = authCtx.tokens.idToken;
  const [fullFriendsList, setFullFriendsList] = useState([]);
  const [filteredFriendsList, setFilteredFriendsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshFollowButtonsTrigger, setRefreshFollowButtonsTrigger] = useState(false);
  const [reloadSelfProfile, selfProfile] = useProfile();
  const [selfFriends, setSelfFriends] = useState(null);
  const { state } = useContext(MatchmakingContext);

  const checkIsSelfFollowing = otherUserId => {
    if (selfProfile && userId === selfProfile.id) return true;
    return selfFriends && selfFriends.some(friendId => friendId === otherUserId);
  };

  const handleSearchInput = event => {
    const filteredData = fullFriendsList.filter(friend =>
      friend.username.toLowerCase().includes(event.target.value.toLowerCase()),
    );
    setFilteredFriendsList(filteredData);
  };

  // If a getFriends route is added to the backend in the future,
  // This logic can be changed to replace the nested loop with a single backend call
  useEffect(() => {
    const fillFriendsList = async () => {
      let calcFriendsIds;
      let calcAllUsersData;

      // Check if all necessary friends data was passed as prop
      if (friendsData !== undefined) {
        if (friendsData === null) {
          // wait for prop value to change
          return;
        }
        setFullFriendsList(friendsData);
        setFilteredFriendsList(friendsData);
        setIsLoading(false);
        return;
      }

      // Else find friends data through fetch requests
      if (friendsIds === null || allUsersData === null) {
        // null indicates these values will be passed from the parent, so wait for them
        return;
      }
      if (friendsIds === undefined) {
        if (userId === undefined) {
          console.error('FriendsList requires userId if friendsIds is not given');
          return;
        }
        if (userId === null) {
          // wait for userId prop to change value
          return;
        }
        console.log('fetch friends prfle');
        const userProfile = await getFriendsProfile(bearerToken, userId);
        calcFriendsIds = userProfile.friends;
      } else {
        calcFriendsIds = friendsIds;
      }
      if (allUsersData === undefined) {
        console.log('fetch all users');
        calcAllUsersData = await allUsers(bearerToken);
      } else {
        calcAllUsersData = allUsersData;
      }
      const calcFriendsData = calcAllUsersData.filter(user => calcFriendsIds.includes(user.id));
      setFullFriendsList(calcFriendsData);
      setFilteredFriendsList(calcFriendsData);
      setIsLoading(false);
    };

    fillFriendsList();
  }, [friendsData, friendsIds, allUsersData, userId]);

  useEffect(() => {
    if (!selfProfile) return;
    setSelfFriends(selfProfile.friends);
  }, [selfProfile]);

  useEffect(() => {
    if (selfFriendsIds === undefined) {
      reloadSelfProfile();
    } else {
      setSelfFriends(selfFriendsIds);
    }
  }, [state.triggerGetFriendsList, selfFriendsIds]);

  // Next two use effects refresh values of follow buttons, which is only relevant on my fights page
  useEffect(() => {
    setRefreshFollowButtonsTrigger(true);
  }, [triggerFollowButtonsRefresh]);

  useEffect(() => {
    if (refreshFollowButtonsTrigger) setRefreshFollowButtonsTrigger(false);
  }, [refreshFollowButtonsTrigger]);

  return (
    <div className="friends-list">
      <IonSearchbar placeholder="Search Friends" className="search-bar" onIonInput={handleSearchInput} />
      {isLoading
        ? Array.from({ length: 6 }).map(() => <FriendsListItemLoader key={generateUniqueKey()} />)
        : filteredFriendsList.map(friend => (
            <FriendsListItem
              key={friend.id}
              userId={friend.id}
              username={friend.username}
              isFollowingInit={
                selfProfile === null || refreshFollowButtonsTrigger ? null : checkIsSelfFollowing(friend.id)
              }
            />
          ))}
    </div>
  );
};

export default FriendsList;
