import React, { useEffect, useState, useContext } from 'react';
import { Client } from '@amityco/ts-sdk';
import { IonToast } from '@ionic/react';
import './UnreadCountPopup.scss';
import { AuthContext } from '../authentication/AuthContext';

const UnreadCountPopup = () => {
  const { accessData, idData } = useContext(AuthContext);
  const [unreadCount, setUnreadCount] = useState(0);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    let liveObjectInstance;

    const handleLiveObject = liveObject => {
      if (liveObject.data) {
        const { unreadCount: updatedUnreadCount, isMentioned } = liveObject.data;

        console.log(`Current unread count: ${updatedUnreadCount}`);
        console.log(`Current isMentioned: ${isMentioned}`);

        setUnreadCount(updatedUnreadCount);
        setShowToast(true);
      } else {
        console.log('No unread count data available');
      }
    };

    const connectClientAndObserveUnreadCount = async () => {
      try {
        if (!Client.isConnected()) {
          await Client.login({ userId: idData.sub, displayName: idData.name });
          console.log('Client connected successfully');
        } else {
          console.log('Client is already connected');
        }

        console.log('Setting up unread count listener');
        liveObjectInstance = Client.getUserUnread(handleLiveObject);

        if (liveObjectInstance) {
          console.log('Unread count listener setup successfully');
        } else {
          console.error('Failed to set up unread count listener');
        }
      } catch (error) {
        console.error('Client connection failed:', error);
      }
    };

    if (idData && accessData) {
      connectClientAndObserveUnreadCount();
    }

    return () => {
      if (liveObjectInstance) {
        liveObjectInstance.off();
        console.log('Unread count listener removed');
      }
    };
  }, [idData, accessData]);

  return (
    <IonToast
      isOpen={showToast}
      message={`You have ${unreadCount} unread messages`}
      position="top-end"
      color="primary"
      duration={3000}
      onDidDismiss={() => setShowToast(false)}
    />
  );
};

export default UnreadCountPopup;
