import React, { createContext, useEffect, useReducer, useMemo } from 'react';
import { refresh } from '../../global/request/auth';

const authTemplate = {
  loggedIn: false,
  checkedStorage: false,
  tokens: {},
  accessData: {},
  idData: {},
};

const AuthContext = createContext({ ...authTemplate });

const AuthDispatchContext = createContext({ dispatch: () => {} });

function AuthContextProvider({ children }) {
  const [auth, dispatch] = useReducer(
    (state, action) => {
      let updatedState = {};

      switch (action.action) {
        case 'checkedStorage': {
          updatedState.checkedStorage = true;
          break;
        }

        case 'loadTokens': {
          updatedState = {
            loggedIn: true,
            accessData: action.authData.accessData,
            idData: action.authData.idData,
            tokens: {
              accessToken: action.authData.tokens.accessToken,
              idToken: action.authData.tokens.idToken,
              refreshToken: action.authData.tokens.refreshToken,
            },
          };

          break;
        }

        case 'storeAuthResult': {
          updatedState = {
            loggedIn: true,
            accessData: action.authResult.accessData,
            idData: action.authResult.idData,
            tokens: {
              accessToken: action.authResult.tokens.accessToken,
              idToken: action.authResult.tokens.idToken,
              refreshToken: action.authResult.tokens.refreshToken,
            },
          };

          break;
        }

        case 'clearAuth': {
          updatedState = { ...authTemplate, checkedStorage: true };
          break;
        }

        default: {
          throw new Error('Invalid action');
        }
      }

      return {
        ...state,
        ...updatedState,
      };
    },
    { ...authTemplate },
  );

  // checking for stored tokens and loading them if they exist
  useEffect(() => {
    (async () => {
      if (localStorage.getItem('tokens') !== null) {
        // tokens found in storage
        const tokens = JSON.parse(localStorage.getItem('tokens'));

        // TODO: stopgap fix
        const splitAccessToken = tokens.accessToken.split('.');
        const decodedAccessToken = JSON.parse(atob(splitAccessToken[1]));
        const splitIdToken = tokens.idToken.split('.');
        const decodedIdToken = JSON.parse(atob(splitIdToken[1]));
        // const decodedAccessToken = await tokenVerifier.verify(tokens.accessToken, { tokenUse: "access" });
        // const decodedIdToken = await tokenVerifier.verify(tokens.idToken, { tokenUse: "id" });

        dispatch({
          action: 'loadTokens',
          authData: {
            idData: decodedIdToken,
            accessData: decodedAccessToken,
            tokens,
          },
        });

        if (decodedAccessToken.exp * 1000 - Date.now() < 0 || decodedIdToken.exp * 1000 - Date.now() < 0) {
          // tokens expired request new ones

          try {
            // get new tokens
            const result = await refresh(tokens.refreshToken);
            dispatch({ action: 'storeAuthResult', authResult: result });
          } catch (error) {
            localStorage.removeItem('tokens');
            dispatch({ action: 'clearAuth' });
          }
        }
      }

      dispatch({ action: 'checkedStorage' });
    })();
  }, []);

  useEffect(() => {
    if (auth.checkedStorage && auth.loggedIn) {
      localStorage.setItem('tokens', JSON.stringify(auth.tokens));
    } else if (auth.checkedStorage && localStorage.getItem('tokens') !== null) {
      localStorage.removeItem('tokens');
    }
  }, [auth.loggedIn, auth.checkedStorage, auth.tokens]);

  const memoizedAuthValue = useMemo(() => auth, [auth]);
  const memoizedDispatchValue = useMemo(() => ({ dispatch }), [dispatch]);

  return (
    <AuthDispatchContext.Provider value={memoizedDispatchValue}>
      <AuthContext.Provider value={memoizedAuthValue}>{children}</AuthContext.Provider>
    </AuthDispatchContext.Provider>
  );
}

export { AuthContext, AuthDispatchContext, AuthContextProvider };
