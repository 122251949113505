import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { arrowDown } from 'ionicons/icons';
import './NewMessagePopup.scss';

const NewMessagePopup = ({ scrollToBottom }) => (
  <div className="new-message-popup">
    <IonButton className="new-message-popup-button" onClick={scrollToBottom}>
      New Messages <IonIcon icon={arrowDown} />
    </IonButton>
  </div>
);

export default NewMessagePopup;
