import React, { useCallback, useEffect, useState, forwardRef, useImperativeHandle } from 'react';

import '../../styles/fight/MessageOverlay.scss';

/**
 * Simple component that displays a message in an overlay. Does not leverage
 * any Ionic components so should be compatible everywhere but used sparingly.
 * @param {Object} props
 * @param {boolean} props.autoExit Whether the overlay should automatically exit after a certain amount of time.
 * @param {boolean} props.blur Whether the background should be blurred.
 * @param {string} props.msg The message to display.
 * @param {number} props.lifespan The amount of time in seconds to display the overlay for if autoExit is true.
 * @param {React.MutableRefObject} ref A ref to the message overlay component.
 * @returns
 */
function MessageOverlay({ autoExit, blur, msg, lifespan }, ref) {
  const [active, setActive] = useState(false);

  const backgroundRef = useCallback(element => {
    if (element) {
      element.addEventListener('click', () => {
        setActive(false);
      });

      backgroundRef.current = element;
    }
  }, []);

  useImperativeHandle(ref, () => {
    return {
      present: () => {
        setActive(true);
      },
      dismiss: () => {
        setActive(false);
      },
    };
  });

  // XXX: can use opacity to ease in/out of the overlay
  useEffect(() => {
    if (active) {
      backgroundRef.current.style.display = 'flex';

      if (autoExit) {
        setTimeout(() => {
          setActive(false);
        }, lifespan * 1000);
      }
    } else {
      backgroundRef.current.style.display = 'none';
    }
  }, [active]);

  return (
    <div className={`overlay-background${blur ? ' blur' : ''}`} ref={backgroundRef}>
      <div className="overlay-msg">{msg}</div>
    </div>
  );
}

export default forwardRef(MessageOverlay);
