import React, { useEffect, useState, useRef } from 'react';
import { IonCard, IonSkeletonText } from '@ionic/react';
import { useSwiper } from 'swiper/react';

export default function CarouselSkeletonLoader({ aspectRatio, slidesInView, gapBtwnSlides }) {
  const isMounted = useRef(false);
  let defaultWidth;
  let defaultHeight;
  if (!isMounted.current) {
    isMounted.current = true;
    /*
    This code only works assuming the swiper width equals the window width
    If this changes, a different estimate would have to be used
    In general, this results in a skeleton that's a few pixels taller than the image,
    resulting in a small jump when loaded.
    I couldn't identify what causes this difference, but it might be worth looking into
    */
    const swiperWidth = window.innerWidth;
    const widthMinusSpaces = swiperWidth - gapBtwnSlides * 2;
    // the below + gapBtwnSlides is needed because of my workaround for slidesOffsetBefore
    const width = widthMinusSpaces / slidesInView + gapBtwnSlides;
    defaultWidth = width;
    defaultHeight = width / aspectRatio;
  }
  const [slideWidth, setSlideWidth] = useState(defaultWidth);
  const [slideHeight, setSlideHeight] = useState(defaultHeight);

  const swiper = useSwiper();

  useEffect(() => {
    const updateSkeletonSize = () => {
      const widthMinusSpaces = swiper.width - gapBtwnSlides * 2;
      // the below + gapBtwnSlides is needed because of my workaround for slidesOffsetBefore
      const width = widthMinusSpaces / slidesInView + gapBtwnSlides;
      setSlideWidth(width);
      setSlideHeight(width / aspectRatio);
    };

    swiper.on('resize', updateSkeletonSize);

    return () => {
      swiper.off('resize', updateSkeletonSize);
    };
  }, [swiper.width]);

  return (
    <IonCard style={{ padding: '0px', margin: '0' }}>
      <IonSkeletonText
        className="ion-no-margin"
        animated
        style={{ width: `${slideWidth}px`, height: `${slideHeight}px`, borderRadius: '4px' }}
      />
    </IonCard>
  );
}
