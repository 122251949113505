import React from 'react';
import { setupIonicReact, IonApp, IonRouterOutlet } from '@ionic/react';
import { Route, Redirect } from 'react-router';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

// Swiper CSS
import 'swiper/css';
import 'swiper/css/mousewheel';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';

// Our CSS
import './styles/utils.scss';
import './styles/global.scss';

import LoadingPage from './LoadingPage';
import SignIn from './components/authentication/SignIn';
import SignUp from './components/authentication/SignUp';
import ResetPassword from './components/authentication/ResetPassword';
import AppTabs from './components/appTabs/AppTabs';
import { AppContextProvider } from './global/AppContext';
import { AuthContext, AuthContextProvider } from './components/authentication/AuthContext';
import { MatchmakingProvider } from './global/MatchmakingContext';
import { ProductTourProvider } from './global/ProductTourContext';
import UnreadCountPopup from './components/chat/UnreadCountPopup';
import Chat from './components/chat/Chat';

setupIonicReact();

/**
 * Simple wrapper for a Route that redirects to a fallback route if a condition is false.
 * @param {Object} props
 * @param {boolean} props.exact
 * @param {string} props.path
 * @param {React.Component} props.component Component to render if condition is true.
 * @param {boolean} props.cond Condition to check.
 * @param {string} props.fallbackRoute Route to redirect to if condition is false.
 * @returns
 */
function ProtectedRoute({ exact, path, component: Component, cond, fallbackRoute }) {
  return <Route exact={exact} path={path} render={() => (cond ? <Component /> : <Redirect to={fallbackRoute} />)} />;
}

function App() {
  return (
    <IonApp>
      <AppContextProvider>
        <AuthContextProvider>
          <MatchmakingProvider>
            <ProductTourProvider>
              <AuthContext.Consumer>
                {authCtx =>
                  !authCtx.checkedStorage ? (
                    <LoadingPage />
                  ) : (
                    <IonReactRouter>
                      <IonRouterOutlet>
                        <Route exact path="/">
                          {authCtx.loggedIn ? <Redirect to="/app/home" /> : <Redirect to="/signin" />}
                        </Route>
                        <Route exact path="/signin" component={SignIn} />
                        <Route exact path="/signup" component={SignUp} />
                        <Route exact path="/reset-password" component={ResetPassword} />
                        <ProtectedRoute
                          path="/app"
                          component={AppTabs}
                          cond={authCtx.loggedIn}
                          fallbackRoute="/signin"
                        />
                        <Route path="/chat/:channelId" component={Chat} />
                        <Route render={() => <div>Route not found</div>} />
                      </IonRouterOutlet>
                      <UnreadCountPopup />
                    </IonReactRouter>
                  )
                }
              </AuthContext.Consumer>
            </ProductTourProvider>
          </MatchmakingProvider>
        </AuthContextProvider>
      </AppContextProvider>
    </IonApp>
  );
}

export default App;
