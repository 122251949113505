import React, { useState, useContext, useEffect, useRef } from 'react';
import { IonButton, IonCard, IonCardContent, IonIcon } from '@ionic/react';
import { closeOutline, eyeOutline } from 'ionicons/icons';
import GamePanel from '../fight/summary/GamePanel';
import { rejectFight, acceptFight } from '../../global/request/bet';
import { AuthContext } from '../authentication/AuthContext';
import BetDetailsModal from '../home/BetDetailsModal';
import ViewDetailsModal from './ViewDetailsModal';
import '../../styles/profile/LiveFightCard.scss';
import nbaTeams from '../../data/Sports/NBA.json';
import nflTeams from '../../data/Sports/NFL.json';
import cfbTeams from '../../data/Sports/NCAAF.json';
import nhlTeams from '../../data/Sports/NHL.json';
import cbbTeams from '../../data/Sports/CBB.json';
import mlbTeams from '../../data/Sports/MLB.json';
import soccerTeams from '../../data/Sports/SOCCER.json';
import useTeamsUIConfig from '../../global/useTeamsUIConfig';
// import carjitsuTeams from '../../data/Sports/CARJITSU.json';

/**
 *
 * @param {Object} props
 * @param {Object} props.fight The fight data to be displayed.
 * @param {string} props.fight.chosenTeam The chosen team for the fight.
 * @param {string} props.fight.challengerTeam The challenger team for the fight.
 * @param {string} props.fight.challengerName The challenger name for the fight.
 * @param {string} props.fight.msg The message for the fight.
 * @param {string} props.fight.time The time for the fight.
 * @param {boolean} props.fight.pending Whether the fight is pending this users' response. If false then it is an outgoing fight pending another person's response.
 * @param {boolean} props.isAccepted Whether the fight is accepted or not.
 * @returns
 */

function LiveFightCard({
  fight: {
    bet,
    makerTeam,
    takerTeam,
    makerTeamColor,
    takerTeamColor,
    makerName,
    takerName,
    makerOutcomePhrase,
    takerOutcomePhrase,
    sport,
    restaurantId,
    msg,
    time,
    pending,
    fightId,
    address,
  },
  onFightAction,
  profileName,
  isAccepted,
  onViewFight,
}) {
  const authCtx = useContext(AuthContext);
  const bearerToken = authCtx.tokens.idToken;
  const isMaker = makerName === profileName;

  const [activeBet, setActiveBet] = useState(null);
  const [isBetDetailsOpen, setIsBetDetailsOpen] = useState(false);
  const [isViewDetailsOpen, setIsViewDetailsOpen] = useState(false);
  const [modalAnimationClass, setModalAnimationClass] = useState('modal-slide-in');
  const [buttonMarginTop, setButtonMarginTop] = useState();
  const cardRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const [reload, specialsTeamsConfig, loading, err] = useTeamsUIConfig();

  const [allTeams, setAllTeams] = useState([
    ...nbaTeams,
    ...nflTeams,
    ...cfbTeams,
    ...nhlTeams,
    ...mlbTeams,
    ...soccerTeams,
    ...cbbTeams,
  ]);

  useEffect(() => {
    setAllTeams(prevTeams => [...prevTeams, ...specialsTeamsConfig]);
  }, []);

  useEffect(() => {
    const namesElement = cardRef.current?.querySelector('.above.cols');
    const resetMargin = () => setButtonMarginTop(namesElement.clientHeight);

    resetMargin();
    window.addEventListener('resize', resetMargin);

    return () => window.removeEventListener('resize', resetMargin);
  }, [cardRef.current, makerName, takerName]);

  const findTeamAcronym = teamName => {
    let foundTeam = allTeams.find(team => team.name === teamName);
    if (!foundTeam) {
      foundTeam = specialsTeamsConfig.find(team => team.name === teamName);
    }
    if (!foundTeam) {
      foundTeam = specialsTeamsConfig.find(team => team.acronym === teamName);
    }
    if (foundTeam) {
      return foundTeam.acronym;
    }
    if (teamName.split(' ')[1]) {
      return teamName.split(' ')[1];
    }
    return teamName;
  };

  const formatPhrase = () => {
    const phrase = profileName === makerName ? makerOutcomePhrase : takerOutcomePhrase;
    const teamName = profileName === makerName ? bet.makerOutcomeTeam : bet.takerOutcomeTeam;
    const teamAcronym = profileName === makerName ? findTeamAcronym(makerTeam) : findTeamAcronym(takerTeam);
    let outcomePhrase = phrase.replace(teamName, teamAcronym);
    if (sport === 'Specials') {
      const outcomes = ['wins', 'win', 'first', 'next'];
      let specialPhrase = teamName;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < outcomes.length; i++) {
        const outcome = outcomes[i];
        if (teamName.includes(outcome)) {
          const specialOutcome = teamName.split(outcome)[1];
          specialPhrase = `${teamAcronym} ${outcome} ${specialOutcome.trim()}`;
          break;
        }
      }
      outcomePhrase = specialPhrase;
    }

    return outcomePhrase;
  };

  const formatMsg = msgPhrase => {
    const outcome = formatPhrase();
    const outcomephrase = profileName === makerName ? makerOutcomePhrase : takerOutcomePhrase;
    msgPhrase = msgPhrase.replace(outcomephrase, outcome);
    msgPhrase = msgPhrase.replace('GAMELINE1', '');
    msg = msgPhrase;
    return msgPhrase;
  };

  const findTeamColor = (teamName, teamColor) => {
    const DEFAULT_COLOR = teamColor;
    let foundTeam = allTeams.find(team => team.name === teamName && team.sport === sport);
    if (!foundTeam) {
      foundTeam = specialsTeamsConfig.find(team => team.name === teamName);
    }
    if (!foundTeam) {
      const teamNameAcr = findTeamAcronym(teamName);
      foundTeam = allTeams.find(team => team.acronym === teamNameAcr && team.sport === sport);
      if (!foundTeam) {
        foundTeam = specialsTeamsConfig.find(team => team.acronym === teamNameAcr);
      }
    }
    const color = foundTeam ? foundTeam.color : DEFAULT_COLOR;
    return color;
  };

  const openModalWithBetData = (fight, modal) => {
    bet.makerOutcomePhrase = formatPhrase();
    bet.takerOutcomePhrase = formatPhrase();
    msg = formatMsg(msg);
    if (sport === 'Specials') {
      bet.makerOutcomeTeamColor = findTeamColor(makerTeam, makerTeamColor);
      bet.takerOutcomeTeamColor = findTeamColor(takerTeam, takerTeamColor);
    } else {
      bet.makerOutcomeTeamColor = makerTeamColor;
      bet.takerOutcomeTeamColor = takerTeamColor;
    }
    if (fight.makerOutcomePhrase.includes('FIGHT')) {
      let firstNameMaker;
      let lastNameMaker;
      if (fight.makerOutcomeTeam.split(' ')[1]) {
        // eslint-disable-next-line no-unused-vars
        [firstNameMaker, lastNameMaker] = fight.makerOutcomeTeam.split(' ');
        fight.makerOutcomeTeam = lastNameMaker;
      }
      let firstNameTaker;
      let lastNameTaker;
      if (fight.takerOutcomeTeam.split(' ')[1]) {
        // eslint-disable-next-line no-unused-vars
        [firstNameTaker, lastNameTaker] = fight.takerOutcomeTeam.split(' ');
        fight.takerOutcomeTeam = lastNameTaker;
      }
    } else {
      fight.makerOutcomeTeam = makerTeam;
      fight.takerOutcomeTeam = takerTeam;
      bet.makerTeamAcronym = findTeamAcronym(makerTeam);
      bet.takerTeamAcronym = findTeamAcronym(takerTeam);
    }
    setActiveBet(fight);
    if (modal === 'BetDetails') {
      setIsBetDetailsOpen(true);
    } else if (modal === 'ViewDetails') {
      setIsViewDetailsOpen(true);
    }
    setModalAnimationClass('modal-slide-in');
  };

  // eslint-disable-next-line no-unused-vars
  const handleView = () => {
    onViewFight(bet);
  };

  const closeModal = () => {
    setModalAnimationClass('modal-slide-out');
    setTimeout(() => {
      if (isBetDetailsOpen) {
        setIsBetDetailsOpen(false);
      } else if (isViewDetailsOpen) {
        setIsViewDetailsOpen(false);
      }
      setActiveBet(null);
    }, 500);
  };

  const formatName = fullName => {
    const names = fullName.trim().split(' ');
    if (names.length > 1) {
      return `${names[0]} ${names[names.length - 1][0]}.`;
    }
    return fullName;
  };

  const handleAccept = async itemId => {
    try {
      await acceptFight(bearerToken, itemId, '', address);
      if (typeof onFightAction === 'function') {
        onFightAction();
      }
    } catch (error) {
      console.error('Error accepting fight:', error);
    }
  };

  const handleReject = async itemId => {
    try {
      await rejectFight(bearerToken, itemId);
      if (typeof onFightAction === 'function') {
        onFightAction();
      }
    } catch (error) {
      console.error('Error rejecting fight:', error);
    }
  };
  const content = (
    <IonCard className="live-fight-card" ref={cardRef}>
      <IonCardContent>
        <div className="cols">
          <div className="lfc-summary">
            <GamePanel
              gameData={{
                sport,
                makerTeam,
                takerTeam,
                makerTeamColor,
                takerTeamColor,
              }}
              leftColor={makerTeamColor}
              rightColor={takerTeamColor}
              // eslint-disable-next-line react/no-unstable-nested-components
              AboveLeft={() => <p>{makerName}</p>}
              // eslint-disable-next-line react/no-unstable-nested-components
              AboveRight={() => <p>{takerName}</p>}
              short
              isHighlightable={false}
            />
            <p>{sport === 'Specials' ? formatMsg(msg) : msg}</p>
            <p className="time-text">{time}</p>
          </div>
          <div
            className="lfc-interactions"
            style={{ visibility: buttonMarginTop ? undefined : 'hidden', paddingTop: `${buttonMarginTop}px` }}
          >
            {sport && sport.toLowerCase?.() !== 'other' && <p className="sport">{sport}</p>}
            <IonButton
              onClick={isAccepted ? () => openModalWithBetData(bet, 'ViewDetails') : () => handleReject(fightId)}
            >
              <IonIcon slot="icon-only" icon={isAccepted ? eyeOutline : closeOutline} />
            </IonButton>
          </div>
        </div>
        {!isMaker && pending && (
          <div className="cols flex-space-between">
            {restaurantId === 64 ? (
              <IonButton className="life-fights-button-accept" onClick={() => openModalWithBetData(bet, 'BetDetails')}>
                Review
              </IonButton>
            ) : (
              <IonButton className="life-fights-button-accept" onClick={() => handleAccept(fightId)}>
                Accept
              </IonButton>
            )}

            <IonButton className="life-fights-button-reject" onClick={() => handleReject(fightId)}>
              Reject
            </IonButton>
          </div>
        )}
      </IonCardContent>
    </IonCard>
  );

  return (
    <div>
      {content}
      <BetDetailsModal
        bet={activeBet}
        isOpen={isBetDetailsOpen}
        onClose={closeModal}
        formatName={formatName}
        modalAnimationClass={modalAnimationClass}
        pendingFight={1}
      />
      <ViewDetailsModal
        bet={activeBet}
        isOpen={isViewDetailsOpen}
        onClose={closeModal}
        modalAnimationClass={modalAnimationClass}
        formatName={formatName}
        isMaker={isMaker}
      />
    </div>
  );
}

export default LiveFightCard;
