import React, { useCallback, useEffect, useState, useRef, forwardRef } from 'react';
import {
  IonButton,
  IonModal,
  IonIcon,
  IonInput,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonBackButton,
  IonTitle,
} from '@ionic/react';
import { closeOutline, send, sendOutline } from 'ionicons/icons';

import MessageOverlay from '../MessageOverlay';

import '../../../styles/fight/challenger/ReferFriendModal.scss';

/**
 * Modal that contains the refer a friend flow. Note this should not be used
 * outside the context of the fighter selection flow due to some workarounds.
 * @param {Object} props
 * @param {React.MutableRefObject} ref A ref to the refer-a-friend modal component.
 * @returns
 */
// Are we using it anywhere??
function ReferFriendModal(props, ref) {
  const msgOverlayRef = useRef();

  const [firstName, setFirstName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [inviteSent, setInviteSent] = useState(false);
  const listenerData = useRef();

  const sendData = useCallback(async () => {
    console.log('sending invite', { firstName, phoneNumber });
  }, [firstName, phoneNumber]);

  useEffect(() => {
    listenerData.current = { sendData };
  }, [sendData]);

  useEffect(() => {
    // eslint-disable-next-line react/destructuring-assignment
    if (ref.current && ref.current.style.bottom !== '0px') {
      // TODO: this calculates before button disappears so unnecessary large offset

      // eslint-disable-next-line react/destructuring-assignment
      ref.current.style.bottom = `${document.querySelector('ion-footer').offsetHeight}px`;
    }
  }, [ref]);

  return (
    <IonModal
      ref={ref}
      onDidPresent={() => {
        document.querySelector('#close-submodal-btn').addEventListener('click', () => {
          // eslint-disable-next-line react/destructuring-assignment
          ref.current.dismiss();
        });

        document.querySelector('#send-invite-btn').addEventListener('click', () => {
          setInviteSent(true);
          msgOverlayRef.current.present();
          listenerData.current.sendData();
        });
      }}
      className="share-modal"
      initialBreakpoint={1}
      breakpoints={[0, 1]}
    >
      <IonHeader className="bet-details_modal_header">
        <IonToolbar className="bet-details_modal_header_toolbar">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle className="bet-details_title bet-details_font">FOODFIGHT VIA TEXT</IonTitle>
          <IonButtons slot="end">
            <IonButton id="close-submodal-btn">
              <IonIcon icon={closeOutline} className="close" slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <div className="share-modal-content">
        <IonInput
          fill="outline"
          placeholder="Opponent Name"
          id="first-name-input"
          onIonInput={event => setFirstName(event.target.value)}
        />
        <IonInput
          type="tel"
          fill="outline"
          placeholder="Phone Number"
          id="phone-input"
          onIonInput={event => setPhoneNumber(event.target.value)}
        />
        <IonButton
          expand="block"
          fill={inviteSent ? 'outline' : 'solid'}
          id="send-invite-btn"
          className={inviteSent ? 'sent' : 'send'}
          disabled={!firstName || !phoneNumber}
        >
          <IonIcon icon={inviteSent ? sendOutline : send} slot="start" />
          <p>{inviteSent ? 'Resend' : 'Confirm'}</p>
        </IonButton>
      </div>
      <MessageOverlay ref={msgOverlayRef} msg="Invite sent!" autoExit lifespan={2.5} blur />
    </IonModal>
  );
}

export default forwardRef(ReferFriendModal);
