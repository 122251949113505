import React from 'react';

const TeamASvg = ({ color, name }) => {
  return (
    <svg
      className="left-svg"
      width="123"
      height="58"
      viewBox="0 0 153 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector 3"
        d="M113.709 0H6C2.68629 0 0 2.68629 0 6V52C0 55.3137 2.68629 58 6 58H92.9472C95.3058 58 97.4456 56.6181 98.4159 54.4683L119.178 8.46831C120.97 4.49721 118.066 0 113.709 0Z"
        fill={color}
      />
      <text x="33%" y="30%" dominantBaseline="middle" textAnchor="middle" fill="white" fontSize="2.5vw">
        {name}
      </text>
    </svg>
  );
};

export default TeamASvg;
