import React from 'react';
import { IonModal, IonToolbar, IonContent, IonFooter } from '@ionic/react';
import '../../styles/home/ImageModal.scss';

const ImageModal = ({ isOpen, onClose, imageSrc, title, modalContent, date }) => {
  return (
    <IonModal className="img-modal" isOpen={isOpen} onDidDismiss={onClose}>
      <IonContent>
        <img className="img-modal__image" src={imageSrc} alt={title} />
        <header className="img-modal__content">
          <h1 className="img-modal__content-header">{title}</h1>
          <p>{date}</p>
          <p dangerouslySetInnerHTML={{ __html: modalContent }} />
        </header>
      </IonContent>
      <IonFooter onClick={onClose}>
        <IonToolbar className="img-modal__toolbar">
          <button style={{ color: '#fff' }} type="button" className="img-modal__button">
            x
          </button>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default ImageModal;
