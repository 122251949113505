import React, { useContext } from 'react';
import { IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { PageContext } from '../appTabs/AppTabs';

/**
 * Header for the start fight modal. Contains a back button and a close button.
 * @param {Object} props
 * @param {string} props.pageName The name of the page to display in the header.
 * @returns
 */
function ModalHeader({ pageName }) {
  const pageCtx = useContext(PageContext);

  return (
    <IonHeader className="ion-no-border">
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton />
        </IonButtons>
        <IonTitle>{pageName}</IonTitle>
        <IonButtons slot="end">
          <IonButton onClick={() => pageCtx.modals.startFight.current.dismiss()}>
            <IonIcon icon={closeOutline} slot="icon-only" />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
}

export default ModalHeader;
