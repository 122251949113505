import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import Header from '../header/Header';

import '../../styles/appTabs/DefaultLanding.scss';

function DefaultLanding() {
  return (
    <IonPage>
      <Header btns="std" />
      <IonContent id="default-landing-page">
        <h1>Route not found</h1>
      </IonContent>
    </IonPage>
  );
}

export default DefaultLanding;
