import React, { useState, useEffect, useRef, useContext } from 'react';
import { IonButton } from '@ionic/react';
import '../../../styles/home/ProductTour.scss';
import { ProductTourContext } from '../../../global/ProductTourContext';

const steps = [
  {
    id: 'step-1',
    description: 'Welcome to FoodFight! Let’s show you around.',
    elements: [],
  },
  {
    id: 'step-2',
    description: 'Set your venue to get started. You can FoodFight in-person or for delivery.',
    elements: [
      {
        id: 'locationButtonRef',
        selector: '.restaurant-icon',
        tooltip: 'Click here to change your venue.',
        position: 'top',
      },
      {
        id: 'restaurantButtonRef',
        selector: '#restaurant-button',
        tooltip: 'Or click here to see venue options.',
        position: 'top',
      },
    ],
  },
  {
    id: 'step-3',
    description: 'You can participate in a FoodFight in 3 ways:',
    elements: [
      {
        id: 'searchBarRef',
        selector: '.toolbar-searchbar',
        tooltip: 'Search for an opponent.',
        position: 'bottom',
      },
      {
        id: 'fightButtonRef',
        selector: '#fight-button',
        tooltip: 'Start and build a custom FoodFight.',
        position: 'top',
      },
      {
        id: 'matchMakingRef',
        selector: '.matchmaking-stream-segment-button',
        tooltip: 'Accept public FoodFights created by other users.',
        position: 'top',
      },
    ],
  },
  {
    id: 'step-4',
    description: 'Want to FoodFight a friend? Invite them to FoodFight!',
    elements: [
      {
        id: 'referalButtonRef',
        selector: '.referal-button',
        tooltip: 'Click here to copy an invitation link you can send to friends.',
        position: 'top',
      },
    ],
  },
  {
    id: 'step-5',
    description: 'Track your history, record, pending and in-play FoodFights. Good luck!',
    elements: [
      {
        id: 'profileButtonRef',
        selector: '#profile-button',
        tooltip: 'Click here to see your profile.',
        position: 'top',
      },
    ],
  },
];

const ProductTour = ({ onComplete }) => {
  const { setElementVisibility, setAllElementsVisible } = useContext(ProductTourContext);
  const refs = useContext(ProductTourContext);

  const [currentStep, setCurrentStep] = useState(0);
  const tooltipRefs = useRef([]);
  const tooltipArrowsRefs = useRef([]);
  const clonedElementsRefs = useRef({});
  const overlayRef = useRef(null);

  const removeCloneElements = () => {
    Object.values(clonedElementsRefs.current).forEach(el => {
      // eslint-disable-next-line no-unused-expressions
      el.parentNode && el.parentNode.removeChild(el); // Remove cloned elements
    });
  };

  const updateTooltipPositions = () => {
    steps[currentStep].elements.forEach(({ id, selector, position }) => {
      let element;
      const tooltip = tooltipRefs.current[id];
      const arrow = tooltipArrowsRefs.current[id];
      if (id === 'restaurantButtonRef' || id === 'fightButtonRef' || id === 'profileButtonRef') {
        element = document.querySelector(selector); // TODO: remove query selection
      } else {
        element = refs[id].current;
      }
      if (element && tooltip) {
        const rect = element.getBoundingClientRect();
        const tooltipRect = tooltip.getBoundingClientRect();

        let tooltipTop;
        if (position === 'top') {
          tooltipTop = rect.top + window.scrollY - tooltipRect.height - 10;
        } else {
          tooltipTop = rect.top + window.scrollY + rect.height + 10;
        }

        let tooltipLeft = rect.left + window.scrollX + rect.width / 2 - tooltipRect.width / 2;

        // adjust tooltip position to stay within viewport
        if (tooltipLeft < 0) tooltipLeft = 0;
        if (tooltipLeft + tooltipRect.width > window.innerWidth) {
          tooltipLeft = window.innerWidth - tooltipRect.width;
        }

        tooltip.style.top = `${tooltipTop}px`;
        tooltip.style.left = `${tooltipLeft}px`;

        if (arrow) {
          arrow.style.left = `${rect.left + rect.width / 2 - tooltipLeft}px`; // center arrow to the element!!!!
        }
      }
    });
  };

  const updateClonedElements = () => {
    setAllElementsVisible();
    removeCloneElements();
    steps[currentStep].elements.forEach(({ id, selector }) => {
      let element;
      if (id === 'restaurantButtonRef' || id === 'fightButtonRef' || id === 'profileButtonRef') {
        element = document.querySelector(selector); // TODO: remove query selection
      } else {
        element = refs[id].current;
      }

      if (element) {
        const rect = element.getBoundingClientRect();
        if (!clonedElementsRefs.current[id]) {
          const clone = element.cloneNode(true);
          clone.classList.add('cloned-element', 'border-overlay');
          clone.style.top = `${rect.top + window.scrollY}px`;
          clone.style.left = `${rect.left + window.scrollX}px`;
          clone.style.width = `${rect.width}px`;
          clone.style.height = `${rect.height}px`;

          // necessary query to fix double search bar issue
          if (clone.classList.contains('header-search')) {
            const searchContainer = clone.querySelector('.searchbar-input-container');
            if (searchContainer) {
              searchContainer.remove();
            }
          }
          clonedElementsRefs.current[id] = clone;
          overlayRef.current.appendChild(clone);
        } else {
          overlayRef.current.appendChild(clonedElementsRefs.current[id]);
        }
        setElementVisibility(id, false);
      }
    });
  };

  useEffect(() => {
    updateClonedElements();
    updateTooltipPositions();
  }, [currentStep]);

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setAllElementsVisible();
      removeCloneElements();
      onComplete();
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const skipTour = () => {
    setAllElementsVisible();
    removeCloneElements();
    onComplete();
  };

  return (
    <div className="tour-overlay" ref={overlayRef}>
      <div className="tour-popup">
        <IonButton className="skip-button" size="small" color="white" onClick={skipTour}>
          Skip
        </IonButton>
        <p className="tour-description">{steps[currentStep].description}</p>
        <div className="progress-dots">
          {steps.map(step => (
            <span key={step.id} className={`dot ${step.id === steps[currentStep].id ? 'active' : ''}`} />
          ))}
        </div>
        <IonButton
          className={`prev-product-button ${currentStep === 0 ? 'firstStep' : ''}`}
          size="small"
          onClick={prevStep}
        >
          Prev
        </IonButton>
        <IonButton className="next-product-button" size="small" onClick={nextStep}>
          Next
        </IonButton>
      </div>
      {steps[currentStep].elements.map(({ id, selector, tooltip, position }) => {
        let element;
        if (id === 'restaurantButtonRef' || id === 'fightButtonRef' || id === 'profileButtonRef') {
          element = document.querySelector(selector); // TODO: remove query selection
        } else {
          element = refs[id].current;
        }
        if (!element) return null;
        const rect = element.getBoundingClientRect();
        return (
          <div
            key={id}
            className="tooltip"
            ref={el => {
              tooltipRefs.current[id] = el;
            }}
            style={{
              top: `${rect.top + window.scrollY - 50}px`,
              left: `${rect.left + window.scrollX}px`,
            }}
          >
            {tooltip}
            <div
              className={`tooltip-arrow ${position === 'top' ? 'tooltip-top' : 'tooltip-bottom'}`}
              ref={el => {
                tooltipArrowsRefs.current[id] = el;
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ProductTour;
