import React from 'react';
import {
  IonModal,
  IonContent,
  IonHeader,
  IonButton,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
} from '@ionic/react';
import '../../styles/home/BetDetailModal.scss';
import '../../styles/fight/summary/GamePanel.scss';

const ModalAfterEarlyReturn = ({ bet, isOpen, onClose, modalAnimationClass, formatName, isMaker }) => {
  const {
    // eslint-disable-next-line no-unused-vars
    makerName,
    takerName,
    restaurantItems,
    makerTeamAcronym,
    takerTeamAcronym,
    makerOutcomeTeamColor,
    takerOutcomeTeamColor,
  } = bet;

  let betTeams;
  let outcome;

  if (isMaker) {
    betTeams = (
      <div className="bet-details_teams">
        <div className="bet-details_leftTeam team" style={{ background: makerOutcomeTeamColor }}>
          <p>YOU</p>
          <h1 className="bet-details_font">{makerTeamAcronym}</h1>
        </div>
        <div className="gap">
          <div className="panel-vs-circle">
            <h1 className="bet-modal-circle">vs</h1>
          </div>
        </div>
        <div className="bet-details_rightTeam team" style={{ background: takerOutcomeTeamColor }}>
          <p>{formatName(takerName)}</p>
          <h1 className="bet-details_font">{takerTeamAcronym}</h1>
        </div>
      </div>
    );
    outcome = <div className="bet-details_outcome">{bet.makerOutcomePhrase}</div>;
  } else {
    betTeams = (
      <div className="bet-details_teams">
        <div className="bet-details_leftTeam team" style={{ background: makerOutcomeTeamColor }}>
          <p>{formatName(makerName)}</p>
          <h1 className="bet-details_font">{makerTeamAcronym}</h1>
        </div>
        <div className="gap">
          <div className="panel-vs-circle">
            <h1 className="bet-modal-circle">vs</h1>
          </div>
        </div>
        <div className="bet-details_rightTeam team" style={{ background: takerOutcomeTeamColor }}>
          <p>YOU</p>
          <h1 className="bet-details_font">{takerTeamAcronym}</h1>
        </div>
      </div>
    );
    outcome = <div className="bet-details_outcome">{bet.takerOutcomePhrase}</div>;
  }

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} className={modalAnimationClass}>
      <IonHeader className="bet-details_modal_header">
        <IonToolbar className="bet-details_modal_header_toolbar">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle className="bet-details_title bet-details_font">FIGHT SUMMARY</IonTitle>
          <IonButtons slot="end">
            <IonButton color="#fff" className="close" onClick={onClose}>
              x
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="details-modal-ion-content">
        <div className="content-wrapper">
          <div className="bet-details_font bet-details_header">MATCHUP</div>
          <div
            className="game-panel"
            style={{
              '--left-color': makerOutcomeTeamColor,
              '--right-color': takerOutcomeTeamColor,
            }}
          >
            {betTeams}
          </div>
          <div className="bet-details_font">YOUR OUTCOME</div>
          {outcome}
          <div>
            <div>
              <div className="bet-details_font">Fight Cart</div>
              <ul className="bet-details_list">
                {restaurantItems.map(item => (
                  <li key={item.menu_item_id}>
                    <div className="fight-items">
                      <div className="item-name">{item.name}</div>
                      {item.cost > 0 ? <div className="item-price">${item.cost.toFixed(2)}</div> : <div />}
                      <div className="item-quantity">{item.quantity}</div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

const ViewDetailsModal = ({ bet, isOpen, onClose, modalAnimationClass, formatName, isMaker }) => {
  if (!bet) return null;
  return (
    <ModalAfterEarlyReturn
      bet={bet}
      isOpen={isOpen}
      onClose={onClose}
      modalAnimationClass={modalAnimationClass}
      formatName={formatName}
      isMaker={isMaker}
    />
  );
};

export default ViewDetailsModal;
