import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { IonText, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonList } from '@ionic/react';
import { searchOutline } from 'ionicons/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel, Scrollbar } from 'swiper/modules';

import { StartFightContext } from '../StartFightContext';
import ModalHeader from '../ModalHeader';
import ItemCard from './ItemCard';

import '../../../styles/fight/items/PickItems.scss';

import useCanGo from '../useCanGo';
import { AppContext } from '../../../global/AppContext';
import { AuthContext } from '../../authentication/AuthContext';
import useMenu from '../../../global/useMenu';
import generateUniqueKey from '../../../UniqueKeyGenerator/UniqueKeyGenerator';

function capitalize(str) {
  return str.replace(/\b\w/g, match => match.toUpperCase());
}

function PickItems({ pageIndex }) {
  const startFightCtx = useContext(StartFightContext);
  // eslint-disable-next-line no-unused-vars
  const appCtx = useContext(AppContext);
  // eslint-disable-next-line no-unused-vars
  const authCtx = useContext(AuthContext);
  useCanGo(pageIndex, () => startFightCtx.items.length > 0, [startFightCtx.items]);

  const [activeCategory, setActiveCategory] = useState();
  // eslint-disable-next-line no-unused-vars
  const [reload, menu, categories] = useMenu();
  const [observer, setObserver] = useState();
  const [itemFilter, setItemFilter] = useState('');

  const swiperRef = useRef();

  const categoryRefs = useCallback(
    node => {
      if (node !== null) {
        if (!categoryRefs.current) {
          categoryRefs.current = [];
        }

        categoryRefs.current.push(node);
        observer?.observe(node);
      }
    },
    [observer],
  );

  useEffect(() => {
    if (!observer) {
      setObserver(
        new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              const newCategory = Number.parseInt(entry.target.getAttribute('category'), 10);
              setActiveCategory(newCategory);
              swiperRef.current.swiper.slideTo(newCategory);
            }
          },
          {
            root: document.querySelector('#menu-list'),
            rootMargin: '0px 0px -75% 0px', // set root margin so "hits" occur towards the upper end of the box
          },
        ),
      );
    }

    // clean up observer
    return () => observer?.disconnect();
  }, [observer]);

  return (
    <>
      <ModalHeader pageName="Pick Items" />
      <IonContent id="pick-items-modal-page" rerender={menu} scrollY={false}>
        <IonItem id="search-bar" lines="none">
          <IonIcon icon={searchOutline} slot="start" />
          <IonInput
            placeholder="Search food and beverage items"
            id="search-input"
            onInput={event => {
              setItemFilter(event.target.value.trim().toLowerCase());
            }}
          />
        </IonItem>
        <Swiper
          modules={[Mousewheel, FreeMode, Scrollbar]}
          mousewheel
          freeMode
          scrollbar={{
            dragSize: window.innerWidth / 5,
          }}
          spaceBetween={30}
          slidesPerView="auto"
          ref={swiperRef}
        >
          {categories?.map((categoryName, index) => {
            return (
              <SwiperSlide key={generateUniqueKey()}>
                <IonText
                  className={activeCategory === index ? 'category-label active' : 'category-label'}
                  onClick={() => {
                    categoryRefs.current[index].scrollIntoView(true);
                  }}
                  category={index}
                >
                  {capitalize(categoryName)}
                </IonText>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <IonList id="menu-list">
          {categories?.length > 0 &&
            menu?.length > 0 &&
            categories
              .map(category => menu.filter(item => item.category === category))
              .map((items, index) => {
                const itemsToShow = items.filter(item => item.name.toLowerCase().includes(itemFilter));

                // TODO: when an item is removed from the foodfight summary the changes are not reflected here
                return (
                  <div
                    className="menu-section"
                    ref={categoryRefs}
                    // XXX: can change later if needed
                    // eslint-disable-next-line react/no-unknown-property
                    category={index}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    style={itemsToShow.length === 0 ? { display: 'none' } : undefined}
                  >
                    <IonLabel className="label-title">{capitalize(categories[index])}</IonLabel>
                    {itemsToShow.map((item, i) => (
                      <ItemCard
                        item={{
                          id: item.id,
                          name: item.name,
                          price: item.price,
                          amount: startFightCtx.items.find(fightItem => fightItem.id === item.id)?.amount || 0,
                        }}
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                      />
                    ))}
                  </div>
                );
              })}
        </IonList>
      </IonContent>
    </>
  );
}

export default PickItems;
