import React from 'react';
import { closeOutline } from 'ionicons/icons';
import { IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonItem, IonLabel } from '@ionic/react';

import '../../styles/firendProfile/unfollowModal.scss';

const UnfollowModal = ({ isOpen, handleCloseModal, handleUnfollowClick }) => {
  return (
    <IonModal
      breakpoints={[0, 1]}
      initialBreakpoint={1}
      isOpen={isOpen}
      onDidDismiss={() => handleCloseModal()}
      className="unfollow-modal"
    >
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={() => handleCloseModal()}>
              <IonIcon slot="icon-only" className="x" size="small" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <div className="unfollow-modal-content ion-padding">
        <IonItem
          onClick={() => {
            handleCloseModal();
            handleUnfollowClick();
          }}
          className="modal-item"
        >
          <IonLabel className="modal-item-label">Unfollow</IonLabel>
        </IonItem>
      </div>
    </IonModal>
  );
};

export default UnfollowModal;
