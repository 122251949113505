import React, { useContext } from 'react';
import { IonRippleEffect } from '@ionic/react';

import pfpBasketball from '../../assets/profiles/foodfight_profile_basketball.png';
import pfpBaseball from '../../assets/profiles/foodfight_profile_baseball.png';
import '../../styles/restaurant/RestaurantTile.scss';
import { AppContext } from '../../global/AppContext';

function RestaurantTile({ onClick, img, name, badge }) {
  const appCtx = useContext(AppContext);

  let tileClass;

  if (appCtx.restaurant && appCtx.restaurant.name === name) {
    tileClass = 'rest-tile selected-tile ion-activatable';
  } else {
    tileClass = 'rest-tile ion-activatable';
  }

  return (
    <div role="button" tabIndex={0} className={tileClass} onClick={onClick}>
      <img src={img} alt="Restaurant" />
      <h1>{name}</h1>
      {/* <div className="cols flex-align-center">
        <p>{props.distance} Feet</p>
        <div className="rest-rating">
          <IonIcon icon={starOutline} />
          <p>{props.rating}</p>
        </div>
      </div> */}
      {badge && (
        <div className="rest-tile-badge">
          <div className="rest-tile-badge-pfps">
            <img src={pfpBaseball} alt="Profile one" />
            <img src={pfpBasketball} alt="Profile Two" />
          </div>
          <div className="rest-tile-badge-overflow">
            <p>+3</p>
          </div>
        </div>
      )}
      <IonRippleEffect />
    </div>
  );
}

export default RestaurantTile;
