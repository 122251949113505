import React, { useEffect, useState } from 'react';
import { IonContent, IonPage } from '@ionic/react';

import './styles/LoadingPage.scss';

function LoadingPage() {
  const [longTime, setLongTime] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLongTime(true);
    }, 1000);
  }, []);

  return (
    <IonPage id="loading-page">
      <IonContent scrollY={false}>
        {longTime && (
          <div>
            <p>Loading...</p>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
}

export default LoadingPage;
