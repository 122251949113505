import React, { useContext, useCallback, useState } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { AppContext } from '../../global/AppContext';
import '../../styles/sports/Sports.scss';
import { PageContext } from '../appTabs/AppTabs';
import Header from '../header/Header';
import GamesList from './GamesList';
import { StartFightContext } from '../fight/StartFightContext';

const Sports = () => {
  const appCtx = useContext(AppContext);
  const pageCtx = useContext(PageContext);
  const startFightCtx = useContext(StartFightContext);
  const [selectedOutcomeSport, setSelectedOutcomeSport] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSportClick = useCallback(
    gameData => {
      if (!appCtx.restaurant) {
        alert('Please select a restaurant first');
        return;
      }
      pageCtx.modals.startFight.current.setInitialState({
        game: gameData,
        outcome: selectedOutcomeSport,
      });
      startFightCtx.dispatch({ action: 'setGame', payload: gameData });
      startFightCtx.dispatch({ action: 'setOutcome', payload: selectedOutcomeSport });
    },
    [appCtx.restaurant, startFightCtx],
  );

  const handleFightClick = useCallback(() => {
    pageCtx.modals.startFight.current.present();
  }, [pageCtx]);

  return (
    <IonPage className="sports-page-wrap">
      <Header placeholder="Search sports..." searchTerm={searchQuery} setSearchTerm={setSearchQuery} />
      <IonContent className="sports-page">
        <GamesList
          handleClick={handleSportClick}
          handleFightClick={handleFightClick}
          isSportPage
          setSelectedOutcomeSport={setSelectedOutcomeSport}
          searchQuery={searchQuery}
        />
      </IonContent>
    </IonPage>
  );
};

export default Sports;
