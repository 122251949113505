import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';

import '../../styles/firendProfile/friendsListPage.scss';
import FriendsList from './FriendsList';
import { getFriendsProfile } from '../../global/request/user';
import { AuthContext } from '../authentication/AuthContext';

const ROUTE_REGEX = /^\/app\/friendprofile\/.*\/friendslist$/;

const FriendsListPage = () => {
  const location = useLocation();
  const params = useParams();
  const authCtx = useContext(AuthContext);

  const bearerToken = authCtx.tokens.idToken;
  const givenState = location.state || {};
  const [friendsIds, setFriendsIds] = useState(null);
  const [username, setUsername] = useState(null);

  useEffect(() => {
    const fetchProfileInfo = async () => {
      const userProfile = await getFriendsProfile(bearerToken, params.userId);
      console.log('friends profile', userProfile);
      setFriendsIds(userProfile.friends);
      setUsername(userProfile.username);
    };

    // Check to avoid an infinite load in the case of an error related to pathname
    if (friendsIds === null) {
      if (givenState.friendsIds && givenState.username) {
        setFriendsIds(givenState.friendsIds);
        setUsername(givenState.username);
      } else {
        fetchProfileInfo();
      }
      return;
    }
    // Reload when this page is switched to
    if (!ROUTE_REGEX.test(location.pathname)) return;
    fetchProfileInfo();
  }, [location.pathname]);

  return (
    <IonPage className="friends-list-page">
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton className="back-button" icon={chevronBackOutline} text="" />
          </IonButtons>
          <IonTitle mode="ios">
            {username || ''} Friends {friendsIds?.length || ''}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <FriendsList allUsersData={givenState.allUsersData} friendsIds={friendsIds} routeRegex={ROUTE_REGEX} />
      </IonContent>
    </IonPage>
  );
};

export default FriendsListPage;
