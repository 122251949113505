import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { IonContent, IonModal, IonNav } from '@ionic/react';
import { StartFightContextProvider } from './StartFightContext';
import PickItems from './items/PickItems';

import '../../styles/fight/StartFightModal.scss';
import ModalFooter from './ModalFooter';
import PickGame from './game/PickGame';
import PickChallenger from './challenger/PickChallenger';
// import PickTeam from './team/PickTeam';
import FightSummary from './summary/FightSummary';

const pgs = [PickGame, PickItems, PickChallenger, FightSummary];

// XXX: automatic background actions still take place (swiper slide changes etc.). could boost performance potentially by pausing them
/**
 * A modal that contains the entire fight setup flow.
 * @param {Object} props
 * @param {boolean} props.open Whether the modal is opened by default. To simply programmatically open/close the modal,
 * use the imperative methods present/dismiss.
 * @param {React.MutableRefObject} ref A ref to the modal component
 * @returns
 */
function StartFightModal({ open }, ref) {
  const [isOpen, setIsOpen] = useState(false);

  const nav = useRef();
  const reducerRef = useRef();
  const backButtonRef = useRef();

  const pageIndex = useRef(1);
  const nextPageFlag = useRef(false);
  const initState = useRef();

  const rootPage = useRef(pgs[0]);
  const nextPage = useRef(pgs[1]);

  useEffect(() => {
    if (open) {
      setIsOpen(true);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    setInitialState: state => {
      initState.current = state;
      if (initState.current.game !== null && initState.current.outcome !== null) {
        if (initState.current.game.id === initState.current.outcome.game_id) {
          nextPageFlag.current = true;

          reducerRef.current?.({
            action: 'setPageIndex',
            pageIndex: 1,
          });
          pageIndex.current = 1;
        }
      }
    },
    clearInitialState: () => {
      initState.current = {
        game: {},
        outcome: {},
        allOutcomeData: [],
        challenger: [],
        pageIndex: 0,
        canGo: [],
        items: [],
        payment: '',
        submitted: false,
      };
      nextPageFlag.current = false;

      reducerRef.current?.({
        action: 'setPageIndex',
        pageIndex: 0,
      });
      pageIndex.current = 0;
    },
    present: () => {
      setIsOpen(true);
    },
    dismiss: () => {
      setIsOpen(false);
    },
    nextPage: () => {
      if (pageIndex.current + 1 < pgs.length) {
        nav.current.push(() => <nextPage.current pageIndex={pageIndex.current + 1} />);
        reducerRef.current?.({
          action: 'setPageIndex',
          pageIndex: pageIndex.current + 1,
        });
      } else {
        // TODO: send request
        reducerRef.current?.({ action: 'submitted' });
      }
    },
  }));

  return (
    <IonModal
      isOpen={isOpen}
      onDidPresent={() => {
        if (nextPageFlag.current) {
          nextPageFlag.current = false;
          reducerRef.current?.({
            action: 'setInitialState',
            state: initState.current,
          });
          // TODO: look into further
          // const nextPageProps = pageIndex.current === pgs.length - 2 ? { stripeFormRef } : null;

          // eslint-disable-next-line react/no-unstable-nested-components
          // nav.current.push(() => <nextPage.current pageIndex={pageIndex.current + 1} />, {
          //   progressAnimation: false,
          // });
          if (!nav.current) {
            console.error('Unable to set root page in modal nav');
          } else {
            const setRootPage = async () => {
              // eslint-disable-next-line react/no-unstable-nested-components
              const success = await nav.current.insert(0, () => <rootPage.current pageIndex={0} />);
              if (success) {
                backButtonRef.current = nav.current.querySelector('ion-back-button');
                backButtonRef.current.style.visibility = 'visible';
              }
            };
            setRootPage();
          }
        }
      }}
      onDidDismiss={() => {
        setIsOpen(false);

        reducerRef.current?.({
          action: 'setPageIndex',
          pageIndex: 0,
        });
        pageIndex.current = 0;
        // eslint-disable-next-line prefer-destructuring
        nextPage.current = pgs[1];
      }}
      className="start-fight-modal"
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      // disabling because causes delay in gesture and modal closing
      // canDismiss={() => !props.confirmClose || window.confirm("Close?")}
    >
      <StartFightContextProvider modal={ref} nav={nav} pageCount={pgs.length} reducerRef={reducerRef}>
        <IonContent scrollY={false}>
          <IonNav
            ref={nav}
            onIonNavDidChange={async event => {
              reducerRef.current?.({
                action: 'setPageIndex',
                pageIndex: event.target.getLength() - 1,
              });
              pageIndex.current = event.target.getLength() - 1;
              nextPage.current = pgs[pageIndex.current + 1];
              if (backButtonRef.current) backButtonRef.current.style.visibility = '';
            }}
            // TODO: look into further
            // eslint-disable-next-line react/no-unstable-nested-components
            root={() =>
              nextPageFlag.current ? <nextPage.current pageIndex={1} /> : <rootPage.current pageIndex={0} />
            }
          />
        </IonContent>
        <ModalFooter nextPage={nextPage} />
      </StartFightContextProvider>
    </IonModal>
  );
}

export default React.forwardRef(StartFightModal);
