import React, { useRef, useEffect, useState } from 'react';
import { IonTextarea } from '@ionic/react';
import generateUniqueKey from '../../UniqueKeyGenerator/UniqueKeyGenerator';

import '../../styles/authentication/SixDigitInput.scss';

// Separated this into its own component to avoid cluttering SignUp.js
/**
 * @param {Object} props
 * @param {function} props.onCodeChange Function to update code in SignUp.js
 */

const SixDigitInput = ({ onCodeChange }) => {
  const inputRefs = useRef([]);
  const [code, setCode] = useState('');
  const [isEnabled, setIsEnabled] = useState(false);
  const uniqueKeys = useRef([...Array(6)].map(() => generateUniqueKey()));

  const handleKeyDown = (e, index) => {
    if (e.target.value.length >= 1 && e.key !== 'Backspace') {
      e.preventDefault();
    } else if (e.key === 'Backspace' && index > 0) {
      if (inputRefs.current[index].value !== '') {
        inputRefs.current[index].value = '';
      }
      inputRefs.current[index - 1].setFocus();
    }
  };

  const handleInputChange = (e, index) => {
    const input = e.target.value;
    if (input.length === 6) {
      const pastedDigits = input.match(/\d/g);
      if (pastedDigits && pastedDigits.length === 6) {
        const newCode = pastedDigits;
        newCode.forEach((digit, i) => {
          inputRefs.current[i].value = digit;
        });
        inputRefs.current[5].setFocus();
        const joinedCode = newCode.join('');
        setCode(joinedCode);
        onCodeChange(joinedCode);
      }
      return;
    }

    if (input.length !== 1 || !/^\d$/.test(input)) {
      inputRefs.current[index].value = '';
      return;
    }

    const newCode = [...code];
    newCode[index] = input;
    const joinedCode = newCode.join('');
    setCode(joinedCode);

    if (index < 5) {
      inputRefs.current[index + 1].setFocus();
    }

    if (joinedCode.length === 6) {
      onCodeChange(joinedCode);
    }
  };

  // Alllow one render for inputs to become enabled
  useEffect(() => {
    if (code[0]) {
      setIsEnabled(true);
    }
  }, [code]);

  // Trigger another render to set focus on newly enabled input 1
  // isEnabled is never set to false so this should only run once
  useEffect(() => {
    if (isEnabled) {
      inputRefs.current[1].setFocus();
    }
  }, [isEnabled]);

  useEffect(() => {
    onCodeChange(code);
  }, [code, onCodeChange]);

  return (
    <div id="wrapper">
      {[...Array(6)].map((_, index) => (
        <IonTextarea
          id="code-input"
          key={uniqueKeys.current[index]}
          ref={ref => {
            inputRefs.current[index] = ref;
          }}
          rows={1}
          onInput={e => handleInputChange(e, index)}
          onKeyDown={e => handleKeyDown(e, index)}
          inputmode="numeric"
          pattern="[0-9]*"
          maxLength={1}
          clearOnEdit
          disabled={index !== 0 && !code[0]}
        />
      ))}
    </div>
  );
};

export default SixDigitInput;
