import React, { useState, forwardRef, useImperativeHandle, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { IonButton, IonButtons, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { close } from 'ionicons/icons';

import '../../styles/restaurant/RestaurantModal.scss';
import { AppDispatchContext, ACTIONS } from '../../global/AppContext';

/**
 * A modal that displays information about a restaurant.
 * @param {Object} props
 * @param {Object} props.restaurant Restaurant object
 * @param {string} props.restaurant.id ID of the restaurant
 * @param {string} props.restaurant.name Name of the restaurant
 * @param {number} props.restaurant.rating Rating of the restaurant
 * @param {string} props.restaurant.address Address of the restaurant
 * @param {string} props.restaurant.number Number of the restaurant
 * @param {string} props.restaurant.dine_in Whether the restaurant has dine in
 * @param {string} props.restaurant.delivery Whether the restaurant has delivery
 * @param {string} props.restaurant.icon Icon of the restaurant
 * @param {React.MutableRefObject} ref Ref to the modal
 * @returns
 */
function RestaurantModal({ restaurant }, ref) {
  const appDispatchCtx = useContext(AppDispatchContext);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  // const [isFormValid, setIsFormValid] = useState(false);

  const [addressDetails, setAddressDetails] = useState({
    streetAddress: restaurant?.address || '',
    apt: '',
    city: '',
    state: '',
    zip: '',
  });

  useEffect(() => {
    const initialAddress = restaurant?.address && restaurant.address !== 'Unset value' ? restaurant.address : '';

    setAddressDetails({
      streetAddress: initialAddress,
      apt: '',
      city: '',
      state: '',
      zip: '',
    });
  }, [restaurant]);

  useImperativeHandle(ref, () => {
    return {
      present: () => setIsOpen(true),
      dismiss: () => setIsOpen(false),
    };
  });

  const handleAddressSubmit = async () => {
    const formattedAddress = `${addressDetails.apt} ${addressDetails.streetAddress}${
      addressDetails.city ? `, ${addressDetails.city}` : ''
    }${addressDetails.state ? `, ${addressDetails.state}` : ''} ${addressDetails.zip}`.trim();

    appDispatchCtx.dispatch({
      action: ACTIONS.SET_RESTAURANT,
      restaurant: { ...restaurant, address: formattedAddress },
    });

    setIsOpen(false);
    setTimeout(() => {
      history.push('/app/home');
    }, 1000);
  };

  const showDetailedAddress = restaurant?.id === 64 || restaurant?.name === 'Delivery';

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)} className="rest-modal">
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle mode="ios">{restaurant?.name}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setIsOpen(false)}>
              <IonIcon icon={close} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <div className="rest-modal-content ion-padding">
        {showDetailedAddress && <img src={restaurant?.icon} alt="Restaurant" style={{ marginBottom: '20px' }} />}
        <div className="rest-modal-address">
          <p>{restaurant?.address}</p>
        </div>
        <img src={restaurant?.icon} alt="Restaurant" />
      </div>
      <div className="rest-modal-footer ion-padding">
        <IonButton expand="block" onClick={handleAddressSubmit}>
          Confirm
        </IonButton>
      </div>
    </IonModal>
  );
}

export default forwardRef(RestaurantModal);
