import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import React, { useEffect, useState, useRef } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Card from './Card';
import CarouselSkeletonLoader from '../skeletalLoaders/CarouselSkeletonLoader';
import '../../styles/home/home-styles.css';
// import carouselImage1x155x100 from './carousel/155x100/1.png';
// import carouselImage2x155x100 from './carousel/155x100/2.png';
// import carouselImage3x124x80 from './carousel/124x80/3.png';
// import carouselImage4x155x100 from './carousel/155x100/4.png';
// import carouselImage5x155x100 from './carousel/155x100/5.png';
// import carouselImage6x124x80 from './carousel/124x80/6.png';
// import carouselImagePLN from './carousel/155x100/PLN.png';
// import carouselImagePLN1 from './carousel/155x100/PLN1.png';
// import carouselImagePLN2 from './carousel/155x100/PLN2.png';
// import carouselImagePLN4 from './carousel/155x100/PLN4.png';
// import carouselImage1x310x200 from './carousel/310x200/1.png';
// import carouselImage2x310x200 from './carousel/310x200/2.png';
// import carouselImage3x310x200 from './carousel/310x200/3.png';
// import carouselImage4x310x200 from './carousel/310x200/4.png';
// import carouselImage5x310x200 from './carousel/310x200/5.png';
// import carouselImage6x310x200 from './carousel/310x200/6.png';
// import carouselImagePLNx310x200 from './carousel/310x200/PLN.png';
// import carouselImagePLN1x310x200 from './carousel/310x200/PLN1.png';
// import carouselImagePLN2x310x200 from './carousel/310x200/PLN2.png';
// import carouselImagePLN4x310x200 from './carousel/310x200/PLN4.png';
// import carouselImagePLNx620x400 from './carousel/620x400/PLN.png';
// import carouselImagePLN1x620x400 from './carousel/620x400/PLN1.png';
// import carouselImagePLN2x620x400 from './carousel/620x400/PLN2.png';
// import carouselImagePLN4x620x400 from './carousel/620x400/PLN4.png';
// import carouselImage1x620x400 from './carousel/620x400/1.png';
// import carouselImage2x620x400 from './carousel/620x400/2.png';
// import carouselImage3x620x400 from './carousel/620x400/3.png';
// import carouselImage4x620x400 from './carousel/620x400/4.png';
// import carouselImage5x620x400 from './carousel/620x400/5.png';
// import carouselImage6x620x400 from './carousel/620x400/6.png';
// import carouselImage1x1280x800 from './carousel/1280x800/1.png';
// import carouselImage2x1280x800 from './carousel/1280x800/2.png';
// import carouselImage3x1280x800 from './carousel/1280x800/3.png';
// import carouselImage4x1280x800 from './carousel/1280x800/4.png';
// import carouselImage5x1280x800 from './carousel/1280x800/5.png';
// import carouselImagePLNx1280x800 from './carousel/1280x800/PLN.png';
// import carouselImagePLN1x1280x800 from './carousel/1280x800/PLN1.png';
// import carouselImagePLN2x1280x800 from './carousel/1280x800/PLN2.png';
// import carouselImagePLN4x1280x800 from './carousel/1280x800/PLN4.png';
// import carouselImage6x1280x800 from './carousel/1280x800/6.png';
// import Marz1280x800 from './carousel/1280x800/Marz.png';
// import Marz620x400 from './carousel/620x400/Marz.png';
// import Marz310x200 from './carousel/310x200/Marz.png';
// import Marz124x80 from './carousel/124x80/Marz.png';

import useCarousel from '../../global/useCarousel';

// This value seems to change with screen sizes for some reason
// It's just an estimate for the skeletal loader
const ASPECT_RATIO = 1.55;
// Set these as constants to be able to set the main breakpoint value easier
const NUM_SLIDES_BREAKPOINT = 1000;
const BREAKPOINTS = {};
BREAKPOINTS[NUM_SLIDES_BREAKPOINT] = {
  slidesPerView: 2.3,
  spaceBetween: 15,
  centeredSlides: false,
  centeredSlidesBounds: false,
};

const Carousel = () => {
  // eslint-disable-next-line no-unused-vars
  const [reload, jsonEntries] = useCarousel();

  // Used isMounted instead of useEffect so this can run before the first render
  const isMounted = useRef(false);
  let initSlidesInView;
  let initSkeletonKeys;
  let initGapBtwnSlides;
  if (!isMounted.current) {
    if (window.innerWidth >= NUM_SLIDES_BREAKPOINT) {
      initSlidesInView = 2.3;
      initSkeletonKeys = [1, 2, 3];
      initGapBtwnSlides = 15;
    } else {
      initSlidesInView = 1.5;
      initSkeletonKeys = [1, 2];
      initGapBtwnSlides = 10;
    }
  }
  const [slidesInView, setSlidesInView] = useState(initSlidesInView);
  const [skeletonKeys, setSkeletonKeys] = useState(initSkeletonKeys);
  const [gapBtwnSlides, setGapBtwnSlides] = useState(initGapBtwnSlides);

  // Used to avoid the brief jump made by Card switching from default to actual value
  const [isCardLoaded, setIsCardLoaded] = useState(false);

  useEffect(() => {
    isMounted.current = true;

    const updateSlidesInView = () => {
      if (window.innerWidth >= NUM_SLIDES_BREAKPOINT) {
        setSlidesInView(2.3);
        setSkeletonKeys([1, 2, 3]);
        setGapBtwnSlides(15);
      } else {
        setSlidesInView(1.5);
        setSkeletonKeys([1, 2]);
        setGapBtwnSlides(10);
      }
    };

    window.addEventListener('resize', updateSlidesInView);
  }, []);

  useEffect(() => {
    const customStyles = `
          .swiper-pagination {
            position: absolute;
            bottom: 0px;
            left: 0;
            transform: translateY(340px);
            width: 100%;
            height: 300%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
          }
          .swiper-pagination .swiper-pagination-bullet {
            width: 130px;
            height: 5px;
            background-color: #EB5529;
            margin: 0 5px;
            display: inline-block;
            border-radius: 50;
          }
        `;
    const styleElement = document.createElement('style');
    styleElement.innerHTML = customStyles;
    document.head.appendChild(styleElement);
  }, []);

  return (
    <Swiper
      slidesPerView={1.5}
      spaceBetween={10}
      // slidesOffsetBefore seems to affect all slides instead of just the first
      // slidesOffsetBefore={10}
      slidesOffsetAfter={10}
      centeredSlides
      centeredSlidesBounds
      centerInsufficientSlides
      className="mySwiper"
      modules={[Autoplay]}
      autoplay={{ delay: 10000 }}
      // workaround to avoid using slidesOffsetBefore,
      // but it causes jitter when sliding from or to the first slide
      onTransitionEnd={swiper => {
        const elem = document.querySelector('.mySwiper');
        if (swiper.activeIndex === 0) {
          elem.style.transform = `translateX(${gapBtwnSlides}px)`;
        } else {
          elem.style.transform = 'translateX(0px)';
        }
      }}
      breakpoints={BREAKPOINTS}
    >
      {!isCardLoaded &&
        Array(Math.ceil(slidesInView))
          .fill()
          .map((_, idx) => (
            <SwiperSlide key={skeletonKeys[idx]}>
              <CarouselSkeletonLoader
                aspectRatio={ASPECT_RATIO}
                slidesInView={slidesInView}
                gapBtwnSlides={gapBtwnSlides}
              />
            </SwiperSlide>
          ))}
      {jsonEntries.map(entry => (
        <SwiperSlide key={entry.key}>
          <Card
            smallImageSrc={entry.pngUrls[0]}
            mediumImageSrc={entry.pngUrls[1] || entry.pngUrls[0]}
            largeImageSrc={entry.pngUrls[2] || entry.pngUrls[1] || entry.pngUrls[0]}
            xlargeImageSrc={entry.pngUrls[3] || entry.pngUrls[2] || entry.pngUrls[1] || entry.pngUrls[0]}
            modalContent={entry.caption}
            setIsCardLoaded={setIsCardLoaded}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
