/** @module global/request/teamsUIConfig */
import RequestUtility from './request';

const SERVICE_URL = 'https://x2qkiqo5if.execute-api.us-east-1.amazonaws.com/default';
const util = new RequestUtility(SERVICE_URL);
const ENDPOINT = 's3file';

async function getConfig(bearerToken) {
  try {
    return await util.authorizedRequest(ENDPOINT, {
      method: 'GET',
      bearerToken,
    });
  } catch (error) {
    console.error('Error in getConfig:', error);
    throw error;
  }
}

export default getConfig;
