import { Client, API_REGIONS, ChannelRepository } from '@amityco/ts-sdk';

const client = Client.createClient(process.env.REACT_APP_AMITY_CHAT_KEY, API_REGIONS.US);

const sessionHandler = {
  sessionWillRenewAccessToken(renewal) {
    renewal.renew();
  },
};

const login = async (userId, displayName) => {
  try {
    const isConnected = await Client.login(
      {
        userId,
        displayName,
      },
      sessionHandler,
    );
    return isConnected;
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};

const createConversationChannel = async (userIds, displayName) => {
  if (!Client.isConnected()) {
    console.error('Client is not connected.');
    return null;
  }

  const newChannel = {
    displayName: `Chat with ${displayName}`,
    tags: ['chat'],
    type: 'conversation',
    userIds,
    metadata: {
      displayName,
    },
  };

  try {
    const { data: channel } = await ChannelRepository.createChannel(newChannel);
    return channel;
  } catch (error) {
    console.error('Error creating channel:', error);
    return null;
  }
};

export { client, login, createConversationChannel };
