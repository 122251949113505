import React, { createContext, useState, useRef } from 'react';

const ProductTourContext = createContext();

const ProductTourProvider = ({ children }) => {
  const [tourSteps, setTourSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [visibility, setVisibility] = useState({
    restaurantButtonRef: true,
    fightButtonRef: true,
    profileButtonRef: true,
    locationButtonRef: true,
    referalButtonRef: true,
    matchMakingRef: true,
    searchBarRef: true,
  });
  const restaurantButtonRef = useRef();
  const fightButtonRef = useRef();
  const profileButtonRef = useRef();
  const locationButtonRef = useRef();
  const referalButtonRef = useRef();
  const matchMakingRef = useRef();
  const searchBarRef = useRef();

  const nextStep = () => setCurrentStep(prev => prev + 1);
  const prevStep = () => setCurrentStep(prev => prev - 1);
  const addStep = step => setTourSteps(prev => [...prev, step]);
  const setElementVisibility = (element, isVisible) => {
    setVisibility(prev => ({ ...prev, [element]: isVisible }));
  };
  const setAllElementsVisible = () => {
    setVisibility({
      restaurantButtonRef: true,
      fightButtonRef: true,
      profileButtonRef: true,
      locationButtonRef: true,
      referalButtonRef: true,
      matchMakingRef: true,
      searchBarRef: true,
    });
  };

  return (
    <ProductTourContext.Provider
      value={{
        tourSteps,
        currentStep,
        visibility,
        setElementVisibility,
        setAllElementsVisible,
        nextStep,
        prevStep,
        addStep,
        restaurantButtonRef,
        fightButtonRef,
        profileButtonRef,
        locationButtonRef,
        referalButtonRef,
        matchMakingRef,
        searchBarRef,
      }}
    >
      {children}
    </ProductTourContext.Provider>
  );
};

export { ProductTourContext, ProductTourProvider };
