import React, { useEffect, useRef } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import MessageOverlay from '../fight/MessageOverlay';

export default function BetStripeForm({ formRef, sendPaymentInfo }) {
  const stripe = useStripe();
  const elements = useElements();
  const msgRef = useRef(null);

  const triggerError = () => {
    if (msgRef.current) {
      msgRef.current.present();
    }
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret);
  }, [stripe]);

  const handleSubmit = async event => {
    event.preventDefault();
    console.log('submitting Form');

    if (!stripe || !elements) {
      console.log('something went wrong');

      return;
    }

    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'http://localhost:3000',
      },
      redirect: 'if_required',
    });

    if (error) {
      console.error('Error occured while submitting Stripe Form: ', error);
      triggerError();
    } else {
      console.log('dispatching payment intent to context');
      sendPaymentInfo(paymentIntent.id);
    }
  };

  const paymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <div>
      <MessageOverlay
        ref={msgRef}
        msg="An error occured while processing the input card, please confirm that the details are correct"
        autoExit
        blur
        lifespan={5}
      />
      <form ref={formRef} id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
      </form>
    </div>
  );
}
