import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react';
import ImageModal from './ImageModal';
import '../../styles/home/home-styles.css';

const Card = ({
  smallImageSrc,
  mediumImageSrc,
  largeImageSrc,
  xlargeImageSrc,
  title,
  content,
  modalContent,
  setIsCardLoaded,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [currentImageSrc, setCurrentImageSrc] = useState(smallImageSrc);
  const [modalImageSrc, setModalImageSrc] = useState(largeImageSrc);
  // Used isSizeSet to ensure setIsCardLoaded is called in the render after the cards are updated to prevent jumping
  const [isSizeSet, setIsSizeSet] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
    setModalImageSrc(largeImageSrc);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const updateImageSrc = () => {
      if (window.innerWidth >= 1200) {
        setCurrentImageSrc(xlargeImageSrc);
      } else if (window.innerWidth >= 800) {
        setCurrentImageSrc(largeImageSrc);
      } else if (window.innerWidth >= 412) {
        setCurrentImageSrc(mediumImageSrc);
      } else {
        setCurrentImageSrc(smallImageSrc);
      }
    };

    updateImageSrc();
    setIsSizeSet(true);
    window.addEventListener('resize', updateImageSrc);

    return () => {
      window.removeEventListener('resize', updateImageSrc);
    };
  }, [smallImageSrc, mediumImageSrc, largeImageSrc, xlargeImageSrc]);

  useEffect(() => setIsCardLoaded(isSizeSet), [isSizeSet]);

  return (
    <>
      <IonCard
        className="homepage__carousel"
        id="carousel-card"
        onClick={handleOpenModal}
        style={{ textAlign: 'center' }}
      >
        <img
          className="homepage__carousel__img"
          id="carousel-image"
          alt="Carousel Img"
          src={currentImageSrc}
          style={{ height: 'auto' }}
        />
        <IonCardHeader className="homepage__carousel__header" id="carousel-card-header" style={{ padding: '0' }}>
          <IonCardTitle className="homepage__carousel__title" id="carousel-card-title">
            {title}
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent className="homepage__carousel__content" id="carousel-card-text">
          {content}
        </IonCardContent>
      </IonCard>

      <ImageModal
        isOpen={showModal}
        onClose={handleCloseModal}
        imageSrc={modalImageSrc}
        title={title}
        modalContent={modalContent}
      />
    </>
  );
};

Card.propTypes = {
  smallImageSrc: PropTypes.string.isRequired,
  mediumImageSrc: PropTypes.string.isRequired,
  largeImageSrc: PropTypes.string.isRequired,
  xlargeImageSrc: PropTypes.string.isRequired,
};

export default Card;
