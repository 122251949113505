/** @module global/useTeamsUIConfig */
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../components/authentication/AuthContext';
import getConfig from './request/teamsUIConfig';

const CACHE_KEY = 'uiConfig';

/**
 * Hook that returns the Teams UI configuration.
 * @returns {[Function, Object, boolean, Object]} A function to reload the configuration and the configuration object itself
 */
function useTeamsUIConfig() {
  const authCtx = useContext(AuthContext);
  const [config, setConfig] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const reload = async () => {
    if (!authCtx.tokens) {
      console.error('AuthContext tokens are not available');
      setError('AuthContext tokens are not available');
      setLoading(false);
      return;
    }

    const bearerToken = authCtx.tokens.idToken;
    if (!bearerToken) {
      console.error('Bearer token is not available');
      setError('Bearer token is not available');
      setLoading(false);
      return;
    }

    try {
      const response = await getConfig(bearerToken);
      const parsedResponse = JSON.parse(response);
      setConfig(parsedResponse);
      localStorage.setItem(CACHE_KEY, JSON.stringify(parsedResponse));
      setError(null);
    } catch (errors) {
      console.error('Error fetching config:', errors);
      setError(errors);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const cachedConfig = localStorage.getItem(CACHE_KEY);
    if (cachedConfig !== null) {
      const parsedConfig = JSON.parse(cachedConfig);
      setConfig(parsedConfig);
      setLoading(false);
      return;
    }
    reload();
  }, []);

  return [reload, config, loading, error];
}

export default useTeamsUIConfig;
