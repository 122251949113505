import React, { useEffect, useState } from 'react';

import capitalize from '../../global/util';

import '../../styles/profile/TabView.scss';

/**
 * Component for a simple tab view.
 * @param {*} props
 * @param {React.Component[]} props.children - The views to be displayed in the tab view.
 * @param {string} props.children[].props.tab - The tab name for the view. This should be unique across views.
 * @param {string} props.children[].props.tabName - The name to be displayed on the tab button. If not set the name will default to the capitalized tab name.
 * @returns
 */

function TabView({ children }) {
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    const derivedTabs = [];

    for (let i = 0; i < children.length; i += 1) {
      const child = children[i];
      if (!child.props.tab) {
        throw new Error('TabView children must have a tab property');
      }

      derivedTabs.push(child);
    }

    setTabs(derivedTabs);

    if (!activeTab) {
      setActiveTab(derivedTabs[0].props.tab);
    }
  }, [children]);

  return (
    <div className="tab-view">
      <div className="tab-view-tab-bar">
        {tabs.map(tab => (
          <div
            role="button"
            tabIndex={0}
            key={tab.props.tab}
            className={`tab-view-tab-btn${tab.props.tab === activeTab ? ' active' : ''}`}
            onClick={() => setActiveTab(tab.props.tab)}
          >
            {tab.props.tabName ?? capitalize(tab.props.tab)}
          </div>
        ))}
      </div>
      <div className="tab-view-active-view">{tabs.find(tab => tab.props.tab === activeTab)}</div>
    </div>
  );
}

export default TabView;
