import React, { memo } from 'react';
import { IonButton, IonCard, IonIcon } from '@ionic/react';

import GamePanel from '../fight/summary/GamePanel';

import '../../styles/sports/MatchCard.scss';
import fightIcon from './sportsBoxes/glove.svg';

/**
 *
 * @param {Object} props.match - Match object
 * @param {string} props.match.time - Time of the match
 * @param {string} props.match.day - Day of the match
 * @param {Object} props.match.homeTeam - Home team object
 * @param {string} props.match.homeTeam.name - Home team name
 * @param {Object} props.match.awayTeam - Away team object
 * @param {string} props.match.awayTeam.name - Away team name
 * @param {Object} props.gameData - Game data object
 * @param {Function} props.handleClick - Function to handle click
 * @returns
 */

const MatchCard = memo(
  ({ match, gameData, handleClick, isSelected, teamSelected, onTeamSelect, isDisabled, handleFightClick, isFight }) => {
    const { time, day, homeTeam, awayTeam } = match;
    const isSpecials = gameData.sport === 'Specials';

    return (
      <IonCard className={`match-card ${isSelected ? 'selected' : ''}`} button onClick={() => handleClick(gameData)}>
        {isSpecials ? (
          <GamePanel
            gameData={{
              sport: 'Specials',
              makerTeam: homeTeam.name,
              takerTeam: awayTeam.name,
            }}
            leftColor={homeTeam.color}
            rightColor={awayTeam.color}
            teamSelected={teamSelected}
            onTeamSelect={onTeamSelect}
            isSelected={isSelected}
            isFight={isFight}
          />
        ) : (
          <GamePanel
            gameData={{
              sport: gameData.league.name,
              makerTeam: homeTeam.name,
              takerTeam: awayTeam.name,
            }}
            leftColor={homeTeam.color}
            rightColor={awayTeam.color}
            teamSelected={teamSelected}
            onTeamSelect={onTeamSelect}
            isSelected={isSelected}
            isFight={isFight}
          />
        )}
        <div className="datetime-panel">
          <h2>{time}</h2>
          <p>{day}</p>
        </div>
        <IonButton className="fight-btn" disabled={isDisabled || !isSelected} onClick={handleFightClick}>
          <IonIcon slot="icon-only" icon={fightIcon} />
        </IonButton>
      </IonCard>
    );
  },
);
MatchCard.displayName = 'MatchCard';

export default MatchCard;
