class RequestUtility {
  url = null;

  default = {
    endpoint: null,
    method: null,
  };

  /**
   *
   * @param {string} service_url URL of the service to send requests to
   * @param {Object} [options] Default options for requests
   * @param {string} [options.defaultMethod] Default method to use for requests
   * @param {string} [options.defaultEndpoint] Default endpoint to use for requests
   */
  constructor(service_url, options) {
    if (service_url === null || service_url === undefined) {
      throw new Error('Service URL must be provided');
    }

    this.url = service_url;

    if (options?.defaultMethod !== undefined) {
      this.default.method = options.defaultMethod;
    }

    if (options?.defaultEndpoint !== undefined) {
      this.default.endpoint = options.defaultEndpoint;
    }
  }

  async request(endpoint, options, body) {
    const response = await fetch(`${this.url}/${endpoint ?? this.default.endpoint}`, {
      method: options?.method ?? this.default.method,
      ...(body && { body: JSON.stringify(body) }),
    });

    if (!response.ok) {
      const response_info = await response.json();
      throw new Error(`Error sending request ${response_info.message ?? '!'}`);
    }

    const result = await response.json();
    return result;
  }

  async authorizedRequest(endpoint, options, body) {
    const response = await fetch(`${this.url}/${endpoint ?? this.default.endpoint}`, {
      method: options?.method ?? this.default.method,
      headers: {
        'Content-Type': 'application/json', // TODO: needed for foodfight creation
        Authorization: `Bearer ${options.bearerToken}`,
      },
      body: JSON.stringify(body),
      /*  ...(body && { body: JSON.stringify(body) }), */
    });

    if (!response.ok) {
      throw new Error('Request failed');
    }

    const result = await response.json();
    return result;
  }

  async authorizedRequestXml(endpoint, options, body) {
    const response = await fetch(`${this.url}/${endpoint ?? this.default.endpoint}`, {
      method: options?.method ?? this.default.method,
      headers: {
        Authorization: `Bearer ${options.bearerToken}`,
      },
      ...(body && { body: JSON.stringify(body) }),
    });

    if (!response.ok) {
      const response_info = await response.json();
      throw new Error(`Error sending request ${response_info.message ?? '!'}`);
    }

    const response_text = await response.text();

    const domParser = new DOMParser();
    const response_doc = domParser.parseFromString(response_text, 'application/xml');

    return response_doc;
  }
}

export default RequestUtility;
