/** @module global/useCarousel */
import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../components/authentication/AuthContext';
import getEntries from './request/carousel';

/**
 * Hook that returns all images to go on the carousel.
 * @returns {[Function, Object[]]} A function to reload the images, the menu itself, and the categories of the menu
 */
function useCarousel() {
  const authCtx = useContext(AuthContext);

  const [jsonEntries, setJsonEntries] = useState([]);

  const reload = async () => {
    if (!authCtx.tokens) return;

    const bearerToken = authCtx.tokens.idToken;
    const response = await getEntries(bearerToken);

    let hasInvalidEntries = false;
    const parsedResult = response.map((entry, idx) => {
      if (!entry.pngs || entry.pngs.length === 0) {
        hasInvalidEntries = true;
        return '';
      }
      return {
        key: idx,
        caption: entry.caption,
        pngUrls: entry.pngs.map(png => `data:image/png;base64,${png.data}`),
      };
    });

    const result = hasInvalidEntries ? parsedResult.filter(str => str) : parsedResult;

    setJsonEntries(result);
  };

  useEffect(() => {
    reload();
  }, []);

  return [reload, jsonEntries];
}

export default useCarousel;
