import { React, useEffect, useState } from 'react';
import '../../../styles/fight/summary/GamePanel.scss';
import nbaTeams from '../../../data/Sports/NBA.json';
import nflTeams from '../../../data/Sports/NFL.json';
import cfbTeams from '../../../data/Sports/NCAAF.json';
import nhlTeams from '../../../data/Sports/NHL.json';
import cbbTeams from '../../../data/Sports/CBB.json';
import mlbTeams from '../../../data/Sports/MLB.json';
import soccerTeams from '../../../data/Sports/SOCCER.json';
// import specialsTeams from '../../../data/Sports/SPECIALS.json';
import useTeamsUIConfig from '../../../global/useTeamsUIConfig';

/**
 * A panel that shows a foodfight's participants and their respective teams.
 * @param {Object} props
 * @param {Object} props.gameData - Game data object
 * @param {string} props.gameData.makerName - Maker's name
 * @param {string} props.gameData.makerTeam - Maker's team
 * @param {string} props.gameData.takerName - Taker's name
 * @param {string} props.gameData.takerTeam - Taker's team
 * @param {string} props.leftColor - Left color
 * @param {string} props.rightColor - Right color
 * @param {Object} props.aboveLeft - Component to display above left
 * @param {Object} props.aboveRight - Component to display above right
 * @param {Object} props.belowLeft - Component to display below left
 * @param {Object} props.belowRight - Component to display below right
 * @param {boolean} props.short - Short layout flag
 * @returns
 */
//

function GamePanel({
  gameData,
  leftColor,
  rightColor,
  AboveLeft,
  AboveRight,
  BelowLeft,
  BelowRight,
  short,
  teamSelected,
  onTeamSelect,
  isSelected,
  isFight = false,
}) {
  // eslint-disable-next-line no-unused-vars
  const { makerName, makerTeam, takerName, takerTeam, makerTeamColor, takerTeamColor } = gameData;
  let { sport } = gameData;
  if (sport === 'Major League Soccer') {
    sport = 'Soccer';
  } else if (sport === 'cfb') {
    sport = 'NCAAF';
  }

  let isSpecials = gameData.sport === 'Specials';
  if (!isSpecials && gameData.sport === 'UFC') {
    isSpecials = 'Specials';
  }

  // eslint-disable-next-line no-unused-vars
  const [reload, specialsTeams, loading, error] = useTeamsUIConfig();
  const [allTeams, setAllTeams] = useState([
    ...nflTeams,
    ...cfbTeams,
    ...nbaTeams,
    ...nhlTeams,
    ...mlbTeams,
    ...soccerTeams,
    ...cbbTeams,
  ]);

  const [selectedTeamPanel, setSelectedTeamPanel] = useState(null);

  useEffect(() => {
    if (Array.isArray(specialsTeams)) {
      setAllTeams(prevTeams => [...prevTeams, ...specialsTeams]);
    }
  }, [specialsTeams]);

  const findTeamAcronym = teamName => {
    const foundTeam = allTeams?.find(team => team.name === teamName);
    if (foundTeam) {
      return foundTeam.acronym;
    }
    if (teamName !== undefined) {
      if (teamName.split(' ')[1]) {
        return teamName.split(' ')[1];
      }
    }

    return teamName;
  };

  const findTeamColor = teamName => {
    let DEFAULT_COLOR = '#0000ff';
    if (teamName === makerTeam && makerTeamColor !== undefined) {
      DEFAULT_COLOR = makerTeamColor;
    }
    if (teamName === takerTeam && takerTeamColor !== undefined) {
      DEFAULT_COLOR = takerTeamColor;
    }
    let foundTeam = allTeams.find(team => team.acronym === teamName && team.sport === sport);
    console.log('foundTeam', foundTeam);
    if (foundTeam === undefined) {
      foundTeam = allTeams.find(team => team.name === teamName && team.sport === sport);
      if (foundTeam === undefined) {
        const teamNameAcr = findTeamAcronym(teamName);
        foundTeam = specialsTeams.find(team => team.acronym === teamNameAcr);
      }
    }
    const color = foundTeam ? foundTeam.color : DEFAULT_COLOR;
    return color;
  };

  const handleTeamClick = (e, team) => {
    e.preventDefault();
    if (!isFight || onTeamSelect === undefined) {
      return;
    }
    const newSelectedTeam = selectedTeamPanel === team ? null : team;
    setSelectedTeamPanel(newSelectedTeam);
    onTeamSelect(newSelectedTeam);
    if (team === teamSelected) {
      setSelectedTeamPanel(newSelectedTeam);
    }
  };

  useEffect(() => {
    if (!isFight || onTeamSelect === undefined) {
      return;
    }
    if (isSelected) {
      setSelectedTeamPanel(teamSelected);
    }
  }, [teamSelected]);

  useEffect(() => {
    if (!isSelected) {
      setSelectedTeamPanel(null);
    }
  }, [isSelected]);

  return (
    <div className={`game-panel ${isSpecials ? 'specials' : ''}`}>
      <div className="above cols">
        {AboveLeft && <AboveLeft className="above-left" />}
        <div className="gap" />
        {AboveRight && <AboveRight className="above-right" />}
      </div>
      <div
        className="panel"
        style={
          isSpecials
            ? {
                '--left-color': findTeamColor(makerTeam),
                '--right-color': findTeamColor(takerTeam),
              }
            : {
                '--left-color': leftColor,
                '--right-color': rightColor,
              }
        }
      >
        <div
          className={`piece left${short ? ' short' : ''}${sport === 'mma' ? ' mma' : ''} 
          ${selectedTeamPanel === makerTeam ? 'leftSelectedPanel' : ''} 
          ${selectedTeamPanel === takerTeam ? 'otherSelectedPanel' : ''}`}
          onClick={e => handleTeamClick(e, makerTeam)}
          role="button"
          type="button"
          tabIndex={0}
        >
          {makerName && <p>{makerName}</p>}
          <h1>{findTeamAcronym(makerTeam)}</h1>
        </div>
        <br />
        <div className="gap">
          <div className="panel-vs-circle">
            <h1>vs</h1>
          </div>
        </div>
        <div
          className={`piece right${short ? ' short' : ''}${sport === 'mma' ? ' mma' : ''} 
          ${selectedTeamPanel === takerTeam ? 'rightSelectedPanel' : ''}
          ${selectedTeamPanel === makerTeam ? 'otherSelectedPanel' : ''}`}
          onClick={e => handleTeamClick(e, takerTeam)}
          role="button"
          type="button"
          tabIndex={0}
        >
          {takerName && <p>{takerName}</p>}
          <h1>{findTeamAcronym(takerTeam)}</h1>
        </div>
      </div>
      <div className="below">
        {BelowLeft && <BelowLeft className="below-left" />}
        {BelowRight && <BelowRight className="below-right" />}
        {selectedTeamPanel === makerTeam && <p className="your-pick-left">your pick</p>}
        {selectedTeamPanel === takerTeam && <p className="your-pick-right">your pick</p>}
      </div>
    </div>
  );
}

export default GamePanel;
