import React, { useRef, useState } from 'react';
import { IonInput } from '@ionic/react';

import '../../styles/authentication/EntryField.scss';

// separated this into its own component bc there is a good amount of styling done

/**
 * Component for an entry field
 * @param {Object} props
 * @param {string} props.placeholder Placeholder text
 * @param {string} props.type Type of input
 * @param {string} [props.value] Value of the input
 * @param {function} props.setter Setter function for the input
 * @param {string} [props.name] Name of input
 * @returns
 */
function EntryField({ placeholder, type, value, setter, name, readonly }) {
  const inputRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [fu, forceUpdate] = useState(0); // TODO: probably a way to do this without a forced update

  return (
    <IonInput
      className="entry-field"
      ref={inputRef}
      placeholder={placeholder}
      fill="outline"
      shape="round"
      type={type}
      name={name}
      onInput={event => {
        setter?.(event.target.value);
        forceUpdate(old => old + 1);
      }}
      value={value ?? inputRef.current?.value ?? ''}
      readonly={readonly}
    />
  );
}

export default EntryField;
