/** @module global/cache */

// XXX: down the line this should use @ionic/storage

/**
 * A simple cache that stores data in memory
 */
class SimpleCache {
  /**
   * @type {Object.<string, any>}
   */
  #data = {};

  #log = []; // purpose of log is to allow for cache invalidation

  /**
   * Creates a new SimpleCache
   */
  // constructor() {}

  /**
   * Stores the given value under the given key
   * @param {string} key Key to store the value under
   * @param {any} value Value to store
   */
  // eslint-disable-next-line no-unused-vars
  store(key, value, options = {}) {
    this.#data[key] = value;
    this.#log.push({ timestamp: Date.now(), key });
  }

  /**
   * Checks if the given key exists in the cache
   * @param {string} key Key to check
   * @returns {boolean} True if the key exists, false otherwise
   */
  has(key) {
    return this.#data[key] !== undefined;
  }

  /**
   * Retrieves the value associated with the given key
   * @param {string} key Key to retrieve the value for
   * @returns {any} The value associated with the given key or null if the key does not exist
   */
  retrieve(key) {
    if (this.#data[key] === undefined) return null;
    return this.#data[key];
  }
}

export default SimpleCache;
