import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { useContext } from 'react';
import { AuthContext } from '../authentication/AuthContext';
import { removeDevice } from '../../global/request/user';

const toggleDevice = async () => {
  const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

  if (!isPushNotificationsAvailable) {
    console.log('plugin: PushNotifications is not available');
    return;
  }
  const authCtx = useContext(AuthContext);

  PushNotifications.addListener('registration', token => {
    removeDevice(authCtx.tokens.idToken, token.value);
  });

  PushNotifications.register();
};

export default toggleDevice;
