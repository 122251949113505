import React, { useContext, useEffect } from 'react';
import { IonButton, IonFooter, IonProgressBar } from '@ionic/react';
import { StartFightContext } from './StartFightContext';
import { PageContext } from '../appTabs/AppTabs';
import { AppContext } from '../../global/AppContext';
import MatchmakingContext from '../../global/MatchmakingContext';

/**
 * Footer for the start fight modal. Contains a progress bar and a next button.
 * @param {Object} props
 * @returns
 */
function ModalFooter() {
  const pageCtx = useContext(PageContext);
  const matchmakingCtx = useContext(MatchmakingContext);
  const appCtx = useContext(AppContext);
  const startFightCtx = useContext(StartFightContext);
  const { footerNav, matchModalRef } = useContext(StartFightContext);
  const pageIndexForGetMatched = 2;

  const isNextEnabled = () => {
    if (startFightCtx.pageIndex === 0) {
      if (startFightCtx.game !== null && startFightCtx.outcome !== null) {
        if (startFightCtx.outcome.id !== undefined) {
          if (startFightCtx.game.id === startFightCtx.outcome.game_id) {
            return true;
          }
        }
      }
      return false;
    }
    if (startFightCtx.pageIndex === pageIndexForGetMatched) {
      return startFightCtx.isMatchedClicked || startFightCtx.challenger?.id;
    }
    if (startFightCtx.pageIndex === startFightCtx.pageCount - 1 && appCtx.restaurant.id === 64) {
      return startFightCtx.canGo[startFightCtx.pageIndex]?.() && startFightCtx.isPaymentComplete;
    }
    return startFightCtx.canGo[startFightCtx.pageIndex]?.();
  };

  const buttonName = startFightCtx.pageIndex === startFightCtx.pageCount - 1 ? 'Submit' : 'Next';

  useEffect(() => {
    if (
      startFightCtx.pageIndex === pageIndexForGetMatched &&
      startFightCtx.challenger?.id &&
      matchmakingCtx.state.challenger?.id
    ) {
      if (isNextEnabled()) {
        pageCtx.modals.startFight.current.nextPage();
      }
    }
  }, [startFightCtx.pageIndex, startFightCtx.challenger, matchmakingCtx.state.challenger, pageCtx.modals.startFight]);

  return (
    <IonFooter className="ion-no-border" ref={footerNav}>
      {startFightCtx.canGo[startFightCtx.pageIndex] && (
        <div className="ion-padding">
          <IonButton
            expand="block"
            onClick={() => {
              if (matchModalRef.current) {
                matchModalRef.current.dismiss(); // Close the MatchMakingModal if it is open
              }
              pageCtx.modals.startFight.current.nextPage();
            }}
            disabled={!isNextEnabled() || startFightCtx.submitted}
          >
            <p>{buttonName}</p>
          </IonButton>
        </div>
      )}
      <IonProgressBar value={startFightCtx.pageIndex / (startFightCtx.pageCount - 1)} />
    </IonFooter>
  );
}

export default ModalFooter;
