import React from 'react';

const TeamBSvg = ({ color, name }) => {
  return (
    <svg
      className="right-svg"
      width="126"
      height="60"
      viewBox="0 0 153 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector 3"
        d="M6.58537 57L117 57C120.314 57 123 54.3137 123 51L123 6.00002C123 2.68631 120.314 1.49388e-05 117 1.4544e-05L28.4465 3.99481e-06C26.1492 3.72114e-06 24.0535 1.31179 23.0496 3.37819L1.18851 48.3782C-0.747436 52.3632 2.15497 57 6.58537 57Z"
        fill={color}
      />
      <text
        x="50%"
        y="30%"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="white"
        fontSize="2.5vw"
        transform="rotate(0)"
      >
        {name}
      </text>
    </svg>
  );
};

export default TeamBSvg;
