import React from 'react';
import { IonSkeletonText } from '@ionic/react';

import '../../styles/restaurant/RestaurantTile.scss';

function RestaurantSkeletonTile() {
  return (
    <div className="rest-skeleton-tile">
      <IonSkeletonText animated style={{ width: '100%', height: '10em', margin: '0 0 4px 0' }} />
      <IonSkeletonText animated style={{ width: '100%', height: '1.5em', margin: '0 0 4px 0' }} />
      <IonSkeletonText animated style={{ display: 'inline-block', width: '30%', height: '1.25em', margin: 0 }} />
      <IonSkeletonText
        animated
        style={{ display: 'inline-block', float: 'right', width: '20%', height: '1.25em', margin: 0 }}
      />
    </div>
  );
}

export default RestaurantSkeletonTile;
