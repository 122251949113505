import React, { useContext, useRef, useState, useEffect } from 'react';
import { IonButton, IonPage, IonContent, useIonRouter, IonLabel } from '@ionic/react';
import EntryField from './EntryField';
import '../../styles/authentication/SignIn.scss';
import logoText from '../../assets/tmp_logo_text.svg';
import logoIcon from '../../assets/tmp_logo_icon.svg';
import { AuthDispatchContext } from './AuthContext';
import { signin } from '../../global/request/auth';
import MessageOverlay from '../fight/MessageOverlay';
import usePushNotifcations from '../notifications/PushNotifications';
import { login as amityLogin } from '../../services/amityClient';

function SignIn() {
  const router = useIonRouter();
  const authDispatchCtx = useContext(AuthDispatchContext);
  const msgRef = useRef();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authErrMsg, setAuthErrMsg] = useState('');
  const [amityLoginStatus, setAmityLoginStatus] = useState(null);

  const handleSignIn = async () => {
    try {
      // Authenticate with our app
      const result = await signin({
        email,
        password,
      });

      console.log('signin result:', result);

      // userInfo
      const userId = result.idData.sub;
      const displayName = result.idData.email;

      console.log('userId:', userId);
      console.log('displayName:', displayName);

      authDispatchCtx.dispatch({
        action: 'storeAuthResult',
        authResult: result,
      });

      const isConnected = await amityLogin(userId, displayName);

      if (isConnected) {
        setAmityLoginStatus('Login to Amity successful');
        router.push('/app/home');
      } else {
        setAmityLoginStatus('Login to Amity failed');
      }
    } catch (error) {
      setAuthErrMsg(error.message);
      msgRef.current.present();
      console.error('handleSignIn error:', error);
    }
  };

  useEffect(() => {
    if (amityLoginStatus) {
      console.log(amityLoginStatus);
    }
  }, [amityLoginStatus]);

  usePushNotifcations();

  return (
    <IonPage>
      <IonContent id="signin-page">
        <div id="signin-content">
          <div id="logo-imgs">
            <img id="logo-icon" src={logoIcon} alt="FoodFight Logo" />
            <img id="logo-text" src={logoText} alt="FoodFight Alphanumeric" />
          </div>
          <p className="challenge-text">Challenge your friends for the tab</p>
          <form id="entry-options" onSubmit={event => event.preventDefault()}>
            <IonLabel className="login-label">Account</IonLabel>
            <EntryField id="email" placeholder="Johndoe@email.com" type="email" setter={setEmail} />
            <IonLabel className="login-label">Password</IonLabel>
            <EntryField id="password" placeholder="***********" type="password" setter={setPassword} />
            <div className="forgot-password" role="button" tabIndex={0} onClick={() => router.push('/reset-password')}>
              Forgot Password?
            </div>
            <IonButton id="signin-btn" expand="block" onClick={handleSignIn}>
              Sign In
            </IonButton>
            <IonButton id="signup-btn" expand="inline-block" fill="outline" onClick={() => router.push('/signup')}>
              Create an Account
            </IonButton>
          </form>
        </div>
        <MessageOverlay blur autoExit lifespan={2.5} msg={authErrMsg} ref={msgRef} />
      </IonContent>
    </IonPage>
  );
}

export default SignIn;
