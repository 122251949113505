import { useEffect, useContext } from 'react';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { addDevice } from '../../global/request/user';
import { AuthContext } from '../authentication/AuthContext';

const usePushNotifications = () => {
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    if (!authCtx.loggedIn) return;

    const setupPushNotifications = async () => {
      // Request permission to use push notifications
      const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
      if (!isPushNotificationsAvailable) {
        console.log('Plugin unavailable');
        return;
      }
      const permStatus = await PushNotifications.requestPermissions();

      PushNotifications.addListener('registration', token => {
        addDevice(authCtx.tokens.idToken, token.value);
      });

      PushNotifications.addListener('registrationError', error => {
        console.error('Push registration error: ', error.error);
      });

      PushNotifications.addListener('pushNotificationReceived', notification => {
        console.log('Push received: ', notification);
      });

      PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        console.log('Push action performed: ', notification.actionId, notification.inputValue);
      });

      if (permStatus.receive === 'granted') {
        await PushNotifications.register();
      } else {
        console.error('Push notification permission not granted');
      }
    };

    setupPushNotifications();
  }, [authCtx.loggedIn, authCtx.tokens.idToken]);
};

export default usePushNotifications;
