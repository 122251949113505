import React from 'react';

import burgerIcon from '../../../assets/foodfight_burger.png';
import logoIcon from '../../../assets/foodfight_red_primary_05_downsized.png';
import glovesIcon from '../../../assets/foodfight_gloves.png';

import '../../../styles/fight/challenger/FighterTile.scss';

/**
 * Simple component representing a single tile in the fighter selection grid.
 * @param {Object} props
 * @param {Object} props.fighter The fighter to display in the tile.
 * @param {boolean} props.selected Whether the tile is selected. If selected the tile will special styling to indicate so.
 * @param {Function} props.onClick The function to call when the tile is clicked.
 * @returns
 */
function FighterTile({ fighter, selected, onClick, iconIndex }) {
  const { username } = fighter;
  const icons = [burgerIcon, logoIcon, glovesIcon];
  const selectedIcon = icons[iconIndex % icons.length]; // Select icon based on index

  const padUsername = () => {
    let minLength = 12;
    if (username.length < 5) {
      minLength = 14;
    }
    const paddingNeeded = minLength - username.length;
    if (paddingNeeded > 0) {
      const halfPadding = Math.floor(paddingNeeded / 2);
      const extraPadding = paddingNeeded % 2;
      return `${' '.repeat(halfPadding)}@${username}${' '.repeat(halfPadding + extraPadding)}`;
    }
    return `@${username}`;
  };

  const paddedUsername = padUsername();

  return (
    <div
      className={`fighter-tile${selected ? ' selected' : ''}`}
      role="button"
      tabIndex={0}
      onClick={event => {
        onClick(event);
      }}
    >
      <img src={selectedIcon} alt="icon" className="icon-img" />
      <p>{paddedUsername}</p>
      {/* <IonRippleEffect /> */}
    </div>
  );
}

export default FighterTile;
