/** @module global/useProfile */
import { useState, useEffect, useContext } from 'react';

import { AuthContext } from '../components/authentication/AuthContext';
import MatchmakingContext from './MatchmakingContext';
import { getProfile } from './request/user';
import SimpleCache from './Cache';

const cache = new SimpleCache();

function useProfile() {
  const authCtx = useContext(AuthContext);
  const { state } = useContext(MatchmakingContext);

  const [profile, setProfile] = useState(null);

  const reload = async () => {
    if (!authCtx.tokens) return;

    const response = await getProfile(authCtx.tokens.idToken);
    setProfile(response);

    cache.store('profile', response);
  };

  useEffect(() => {
    // TODO: does not check if cached profile matches current user (if user changes)
    if (!cache.has('profile')) {
      reload();
    } else {
      setProfile(cache.retrieve('profile'));
    }
  }, []);

  useEffect(() => {
    if (cache.has('profile')) {
      setProfile(cache.retrieve('profile'));
    }
  }, [state.triggerGetCachedProfile]);

  return [reload, profile];
}

export default useProfile;
