import React, { useContext } from 'react';
import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import '../../styles/home/home-styles.css';
import { ProductTourContext } from '../../global/ProductTourContext';
// eslint-disable-next-line react/destructuring-assignment
const MatchmakingStreamSegment = props => {
  const { matchMakingRef, visibility } = useContext(ProductTourContext);

  return (
    <IonSegment
      mode="md"
      className="matchmaking-stream-segment"
      // eslint-disable-next-line react/destructuring-assignment
      value={props.selectedSegmentView}
      // eslint-disable-next-line react/destructuring-assignment
      onIonChange={e => props.setSelectedSegmentView(e.detail.value)}
    >
      <IonSegmentButton
        ref={matchMakingRef}
        className={`matchmaking-stream-segment-button ${visibility.matchMakingRef ? '' : 'hidden'}`}
        value="matchmaking"
      >
        <IonLabel>Matchmaking</IonLabel>
      </IonSegmentButton>
      {/* <IonSegmentButton className="matchmaking-stream-segment-button" value="stream">
        <IonLabel>Stream</IonLabel>
      </IonSegmentButton> */}
    </IonSegment>
  );
};

export default MatchmakingStreamSegment;
