import { IonContent, IonPage, IonChip, useIonRouter, IonButton, IonIcon } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { AuthContext } from '../authentication/AuthContext';
import { PageContext } from '../appTabs/AppTabs';
import MatchmakingContext from '../../global/MatchmakingContext';
import Header from '../header/Header';
import TabView from './TabView';
import LiveFights from './LiveFights';
import FightHistory from './FightHistory';
import FriendsList from '../friendProfile/FriendsList';
import gearIcon from '../../assets/icons/gearIcon.svg';
import generateUniqueKey from '../../UniqueKeyGenerator/UniqueKeyGenerator';

import '../../styles/profile/Profile.scss';

import profilePic from '../../assets/profiles/foodfight_profile_baseball.png';
import useProfile from '../../global/useProfile';
import { getFinishedBetsPage, getLiveBets } from '../../global/request/bet';
// eslint-disable-next-line no-unused-vars
const HISTORY_SLICE_LENGTH = 6;
// Used to reload content when this component is routed to
const COMPONENT_ROUTE_NAME = '/app/profile';
// For friendslist component prop
// const COMPONENT_ROUTE_REGEX = /^\/app\/profile$/;

function Profile() {
  // eslint-disable-next-line no-unused-vars
  const router = useIonRouter();
  const location = useLocation();
  const authCtx = useContext(AuthContext);
  const pageCtx = useContext(PageContext);
  const { state } = useContext(MatchmakingContext);
  // eslint-disable-next-line no-unused-vars
  const [reload, profileData] = useProfile();
  // eslint-disable-next-line no-unused-vars
  const [loadedFights, setLoadedFights] = useState([]);
  const [liveFights, setLiveFights] = useState([]);
  const [hasFriendsListChanged, setHasFriendsListChanged] = useState(true);
  const [triggerFollowButtonsRefresh, setTriggerFollowButtonsRefresh] = useState(false);

  useEffect(() => {
    (async () => {
      setLiveFights(await getLiveBets(authCtx.tokens.idToken));
    })();
  }, []);

  useEffect(() => {
    setHasFriendsListChanged(true);
  }, [state.triggerGetFriendsList]);

  // exclude dependency of hasFriendsListChanged so friends list doesn't update while page is open
  useEffect(() => {
    if (location.pathname !== COMPONENT_ROUTE_NAME || !hasFriendsListChanged) return;
    reload();
    setTriggerFollowButtonsRefresh(prev => !prev);
    setHasFriendsListChanged(false);
  }, [location.pathname]);

  return (
    <IonPage className="profile-page-wrapper">
      <Header
        hasSearchBar={false}
        btns={[
          <IonButton key="settings" onClick={() => pageCtx.modals.settings.current.present()}>
            <IonIcon src={gearIcon} slot="icon-only" />
          </IonButton>,
        ]}
      />
      <IonContent id="profile-page">
        <div id="profile-info">
          <div id="profile-info-top" className="cols">
            <img src={profilePic} id="profile-pic" alt="Profile" />
            <h1 id="profile-name">{profileData?.name}</h1>
            <div id="profile-info-text">
              {/* TODO: how should we handle long names? */}
              <h1>{profileData?.name}</h1>
              {/* <div className="cols">
                <div className="col">
                  <p>Friends</p>
                  <p>{profileData?.friendsCount}</p>
                </div>
                <div className="col">
                  <p>Streak</p>
                  <p>{profileData?.streak}</p>
                </div>
                <div className="col">
                  <p>Visited</p>
                  <p>{profileData?.visited}</p>
                </div>
              </div> */}
              <div id="fav-sports" className="cols">
                {profileData?.favSports?.map(sport => (
                  <IonChip key={generateUniqueKey()} className="fav-sports-chip">
                    {sport.toUpperCase()}
                  </IonChip>
                ))}
              </div>
            </div>
          </div>
          <p id="bio">{/* profileData?.bio */}</p>
        </div>
        <TabView>
          <LiveFights tab="live" liveFights={liveFights} routeName={COMPONENT_ROUTE_NAME} />
          <FightHistory
            tab="history"
            loadedFights={loadedFights}
            // loadMoreFights={event => {
            //     const remainingFights = profileData.totalFights - loadedFights.length;
            //     const remainingFights = historyData.foodfights.length - loadedFights.length;
            //     if (remainingFights > 0) {
            //         loadFights(remainingFights > HISTORY_SLICE_LENGTH ? HISTORY_SLICE_LENGTH : remainingFights);
            //     } else {
            //         event.target.disabled = true;
            //     }
            //     event.target.complete();
            // }}
            fetchFights={page => getFinishedBetsPage(authCtx.tokens.idToken, page)}
            record={{
              wins: profileData?.wins,
              losses: profileData?.losses,
              ties: profileData?.ties,
            }}
            profileId={profileData?.id}
            profileName={profileData?.name}
            routeName={COMPONENT_ROUTE_NAME}
          />
          <FriendsList
            tab="friends"
            friendsIds={profileData?.friends || null}
            triggerFollowButtonsRefresh={triggerFollowButtonsRefresh}
            selfFriendsIds={profileData?.friends || null}
          />
        </TabView>
      </IonContent>
    </IonPage>
  );
}

export default Profile;
