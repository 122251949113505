/** @module global/useMenu */
import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../components/authentication/AuthContext';
import { AppContext } from './AppContext';
import { getMenu } from './request/restaurant';
import SimpleCache from './Cache';

const cache = new SimpleCache();

// XXX: do we want a preload function?

/**
 * Hook that returns the menu of the current restaurant. Caches data to prevent unnecessary requests.
 * @returns {[Function, types.MenuItem[], String[]]} A function to reload the menu, the menu itself, and the categories of the menu
 */
function useMenu() {
  const appCtx = useContext(AppContext);
  const authCtx = useContext(AuthContext);
  const [menu, setMenu] = useState([]);
  const [categories, setCategories] = useState([]);

  const reload = async () => {
    if (!appCtx.restaurant || !authCtx.tokens) return;

    const response = await getMenu(appCtx.restaurant.id, authCtx.tokens.idToken);
    setMenu(response);

    const derivedCategories = new Set();
    response.map(item => !derivedCategories.has(item.category) && derivedCategories.add(item.category));
    setCategories(Array.from(derivedCategories));

    cache.store(appCtx.restaurant.id, {
      menu: response,
      categories: Array.from(derivedCategories),
    });
  };

  useEffect(() => {
    if (!cache.has(appCtx.restaurant.id)) {
      reload();
    } else {
      const data = cache.retrieve(appCtx.restaurant.id);
      setMenu(data.menu);
      setCategories(data.categories);
    }
  }, []);

  return [reload, menu, categories];
}

export default useMenu;
