import React, { useContext, useCallback } from 'react';
import { IonContent } from '@ionic/react';
import ModalHeader from '../ModalHeader';
import GamesList from '../../sports/GamesList';
import { PageContext } from '../../appTabs/AppTabs';
import { StartFightContext } from '../StartFightContext';
import useCanGo from '../useCanGo';

const PickGame = () => {
  const pageCtx = useContext(PageContext);
  const startFightCtx = useContext(StartFightContext);
  useCanGo(0, () => startFightCtx.outcome !== null);

  const handleSportClick = useCallback(
    gameData => {
      startFightCtx.dispatch({ action: 'setGame', payload: gameData });
      console.log('ctx in pick game');
    },
    [startFightCtx],
  );

  const handleFightClick = useCallback(() => {
    console.log('are we doing something here?');
    pageCtx.modals.startFight.current.nextPage();
  }, [pageCtx]);

  return (
    <>
      <ModalHeader pageName="Pick Game" />
      <IonContent className="sports-page">
        <GamesList handleClick={handleSportClick} handleFightClick={handleFightClick} />
      </IonContent>
    </>
  );
};

export default PickGame;
