import { useEffect, useContext } from 'react';

import { StartFightContext } from './StartFightContext';

/**
 * Hook that sets the canGo function for a page with the supplied page index.
 * @param {number} pgIndex The index of the page to set the canGo function for.
 * @param {Function} [canGoNext] The canGo function to set. Must return a boolean
 * @param {Array} [deps] The dependencies of the canGo function. If any of these dependencies change, the canGo function will be updated.
 */
function useCanGo(pgIndex, canGoNext = () => true, deps = []) {
  const startFightCtx = useContext(StartFightContext);

  useEffect(() => {
    startFightCtx.dispatch({
      action: 'setCanGo',
      canGo: canGoNext,
      index: pgIndex,
    });

    return () =>
      startFightCtx.dispatch({
        action: 'setCanGo',
        canGo: null,
        index: pgIndex,
      });
  }, deps);
}

export default useCanGo;
