import React, { useRef, useEffect, useState } from 'react';
import { IonContent, IonList, IonItem, IonLabel, IonPopover, IonInput, IonButton } from '@ionic/react';
import './ChatContent.scss';
import { MessageRepository } from '@amityco/ts-sdk';
import NewMessagePopup from './NewMessagePopup';

const formatTime = date => {
  const options = { hour: '2-digit', minute: '2-digit' };
  return new Date(date).toLocaleTimeString([], options);
};

const formatDate = date => {
  const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
  return new Date(date).toLocaleDateString([], options);
};

const isSameDay = (date1, date2) => {
  return new Date(date1).toLocaleDateString() === new Date(date2).toLocaleDateString();
};

const ChatContent = ({ messages, idData, isLoading, loadMoreMessages, channelId }) => {
  const chatContainerRef = useRef(null);
  const [hasLoadedInitialMessages, setHasLoadedInitialMessages] = useState(false);
  const [scrollPositionBeforeLoad, setScrollPositionBeforeLoad] = useState(0);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
  const [showNewMessagePopup, setShowNewMessagePopup] = useState(false);
  const [messageCount, setMessageCount] = useState(messages.length);
  const [popoverState, setPopoverState] = useState({
    showPopover: false,
    event: null,
    messageId: null,
    creatorId: null,
  });
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [newMessageText, setNewMessageText] = useState('');

  useEffect(() => {
    if (chatContainerRef.current && messages.length > 0 && !hasLoadedInitialMessages) {
      chatContainerRef.current.scrollToBottom(0);
      setHasLoadedInitialMessages(true);
    }
  }, [messages, channelId, hasLoadedInitialMessages]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.getScrollElement().then(scrollElem => {
        const { scrollTop, scrollHeight, clientHeight } = scrollElem;
        setIsScrolledToBottom(scrollTop + clientHeight === scrollHeight);
      });
    }
  }, [messages]);

  useEffect(() => {
    const newMessagesCount = messages.length - messageCount;
    const newMessages = messages.slice(-newMessagesCount);
    const nonUserMessages = newMessages.filter(msg => msg.creatorId !== idData.sub);

    if (nonUserMessages.length > 0 && nonUserMessages.length < 10 && newMessagesCount > 0) {
      if (!isScrolledToBottom) {
        setShowNewMessagePopup(true);
      }
    }

    setMessageCount(messages.length);
  }, [messages, messageCount, isScrolledToBottom]);

  const handleScroll = async ({ detail: { scrollTop } }) => {
    if (scrollTop === 0) {
      chatContainerRef.current.getScrollElement().then(scrollElem => {
        setScrollPositionBeforeLoad(scrollElem.scrollHeight);
        loadMoreMessages();
      });
    } else {
      const scrollElem = await chatContainerRef.current.getScrollElement();
      const isAtBottom = scrollElem.scrollHeight - scrollElem.scrollTop === scrollElem.clientHeight;
      setIsScrolledToBottom(isAtBottom);
      if (isAtBottom) {
        setShowNewMessagePopup(false);
      }
    }
  };

  useEffect(() => {
    if (scrollPositionBeforeLoad && messages.length > 0) {
      chatContainerRef.current.getScrollElement().then(scrollElem => {
        const newScrollHeight = scrollElem.scrollHeight;
        chatContainerRef.current.scrollToPoint(0, newScrollHeight - scrollPositionBeforeLoad, 0);
      });
    }
  }, [messages, scrollPositionBeforeLoad]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollToBottom(0);
      setShowNewMessagePopup(false);
    }
  };

  const handleDeleteMessage = async messageId => {
    try {
      await MessageRepository.softDeleteMessage(messageId);
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  const handleEditMessage = messageId => {
    const messageToEdit = messages.find(message => message.messageId === messageId);
    if (messageToEdit) {
      setEditingMessageId(messageId);
      setNewMessageText(messageToEdit.data.text);
    }
  };

  const handleSaveEdit = async () => {
    if (editingMessageId) {
      try {
        const updatedMessage = {
          data: {
            text: newMessageText,
          },
          metadata: {
            edited: true,
          },
        };
        await MessageRepository.updateMessage(editingMessageId, updatedMessage);
        setEditingMessageId(null);
      } catch (error) {
        console.error('Error editing message:', error);
      }
    }
  };

  const openPopover = (event, messageId, creatorId) => {
    setPopoverState({ showPopover: true, event, messageId, creatorId });
  };

  const renderMessagesWithDate = () => {
    const renderedMessages = [];
    let lastMessageDate = null;

    messages.forEach(message => {
      const messageDate = new Date(message.createdAt);
      const formattedMessageDate = formatDate(messageDate);

      if (!lastMessageDate || !isSameDay(lastMessageDate, messageDate)) {
        renderedMessages.push(
          <div key={`date-${message.createdAt}`} className="date-marker">
            <div>{formattedMessageDate}</div>
          </div>,
        );
        lastMessageDate = messageDate;
      }

      const isSentByCurrentUser = message.creatorId === idData.sub;
      const isEditing = editingMessageId === message.messageId;

      renderedMessages.push(
        <div
          key={message.messageId || message.createdAt}
          className={`message-row ${isSentByCurrentUser ? 'sent' : 'received'}`}
        >
          {isSentByCurrentUser ? (
            <>
              <div className="message-options-wrapper left">
                <IonButton
                  fill="clear"
                  className="three-dots-menu"
                  onClick={event => openPopover(event, message.messageId, message.creatorId)}
                >
                  ⋮
                </IonButton>
              </div>
              <IonItem className="message-item sent">
                {isEditing ? (
                  <div className="edit-message-wrapper">
                    <IonInput
                      value={newMessageText}
                      onIonChange={e => setNewMessageText(e.detail.value)}
                      placeholder="Edit your message"
                    />
                    <div className="message-buttons-edit-wrapper">
                      <IonButton className="message-save-edit" onClick={handleSaveEdit}>
                        Save
                      </IonButton>
                      <IonButton className="message-cancel-edit" onClick={() => setEditingMessageId(null)}>
                        Cancel
                      </IonButton>
                    </div>
                  </div>
                ) : (
                  <>
                    <p>{message.isDeleted ? '' : message.data.text}</p>
                    <IonLabel className="message-time">{formatTime(message.createdAt)}</IonLabel>
                    {message.metadata?.edited && (
                      <div className="edited-label">
                        <IonLabel>edited</IonLabel>
                      </div>
                    )}
                  </>
                )}
              </IonItem>
            </>
          ) : (
            <>
              <IonItem className="message-item received">
                {isEditing ? (
                  <div className="edit-message-wrapper">
                    <IonInput
                      value={newMessageText}
                      onIonChange={e => setNewMessageText(e.detail.value)}
                      placeholder="Edit your message"
                    />
                    <div className="message-buttons-edit-wrapper">
                      <IonButton className="message-save-edit" onClick={handleSaveEdit}>
                        Save
                      </IonButton>
                      <IonButton className="message-cancel-edit" onClick={() => setEditingMessageId(null)}>
                        Cancel
                      </IonButton>
                    </div>
                  </div>
                ) : (
                  <>
                    <p>{message.isDeleted ? '' : message.data.text}</p>
                    <IonLabel className="message-time">{formatTime(message.createdAt)}</IonLabel>
                    {message.metadata?.edited && (
                      <div className="edited-label">
                        <IonLabel>edited</IonLabel>
                      </div>
                    )}
                  </>
                )}
              </IonItem>
              <div className="message-options-wrapper right">
                <IonButton
                  fill="clear"
                  className="three-dots-menu"
                  onClick={event => openPopover(event, message.messageId, message.creatorId)}
                >
                  ⋮
                </IonButton>
              </div>
            </>
          )}
        </div>,
      );
    });

    return renderedMessages;
  };

  return (
    <IonContent ref={chatContainerRef} scrollEvents onIonScroll={handleScroll}>
      <IonList className="chat-list">{renderMessagesWithDate()}</IonList>
      {isLoading && <p className="loading-indicator">Loading...</p>}

      {showNewMessagePopup && !isScrolledToBottom && <NewMessagePopup scrollToBottom={scrollToBottom} />}

      <IonPopover
        isOpen={popoverState.showPopover}
        event={popoverState.event}
        onDidDismiss={() => setPopoverState({ showPopover: false, event: null, messageId: null, creatorId: null })}
      >
        <IonContent className="chat-message-handling-section">
          <IonList>
            <IonItem
              className="message-copy-button"
              button
              onClick={() => {
                const message = messages.find(msg => msg.messageId === popoverState.messageId)?.data.text;
                if (message) {
                  navigator.clipboard.writeText(message).catch(err => {
                    console.error('Failed to copy text: ', err);
                  });
                }
                setPopoverState({ showPopover: false, event: null, messageId: null });
              }}
            >
              <IonLabel>Copy</IonLabel>
            </IonItem>
            {popoverState.creatorId === idData.sub && (
              <>
                <IonItem
                  className="message-delete-button"
                  button
                  onClick={() => {
                    handleDeleteMessage(popoverState.messageId);
                    setPopoverState({ showPopover: false, event: null, messageId: null });
                  }}
                >
                  <IonLabel>Delete</IonLabel>
                </IonItem>
                <IonItem
                  className="message-edit-button"
                  button
                  onClick={() => {
                    handleEditMessage(popoverState.messageId);
                    setPopoverState({ showPopover: false, event: null, messageId: null });
                  }}
                >
                  <IonLabel>Edit</IonLabel>
                </IonItem>
              </>
            )}
          </IonList>
        </IonContent>
      </IonPopover>
    </IonContent>
  );
};

export default ChatContent;
