import React, { useContext, useEffect, useRef } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { StartFightContext } from '../StartFightContext';
import MessageOverlay from '../MessageOverlay';

export default function StripeForm() {
  const msgRef = useRef(null);
  const stripe = useStripe();
  const elements = useElements();
  const startFightCtx = useContext(StartFightContext);
  const { formRef } = startFightCtx;

  const triggerError = () => {
    if (msgRef.current) {
      msgRef.current.present();
    }
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          console.log('Payment succeeded!');
          break;
        case 'processing':
          console.log('Your payment is processing.');
          break;
        case 'requires_payment_method':
          console.log('Your payment was not successful, please try again.');
          break;
        default:
          console.error('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handlePaymentElementChange = event => {
    console.log('Payment details completeness changed:', event.complete);
    startFightCtx.setPaymentComplete(event.complete);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    console.log('submitting Form');

    if (!stripe || !elements) {
      console.log('something went wrong');
      return;
    }

    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'http://localhost:3000',
      },
      redirect: 'if_required',
    });

    if (error) {
      console.error('Error occured while submitting Stripe Form: ', error);
      startFightCtx.submitted = false;
      triggerError();
    } else {
      console.log('dispatching payment intent to context');
      startFightCtx.dispatch({
        action: 'setPayment',
        payment: paymentIntent.id,
      });
    }
  };

  const paymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <div>
      <MessageOverlay
        ref={msgRef}
        msg="An error occured while processing the input card, please confirm that the details are correct"
        autoExit
        blur
        lifespan={5}
      />
      <form ref={formRef} id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} onChange={handlePaymentElementChange} />
      </form>
    </div>
  );
}
