import React, { cloneElement, useEffect, useState, useContext } from 'react';
import { IonHeader, IonToolbar, IonImg, IonSearchbar, useIonRouter, IonButtons } from '@ionic/react';
import generateUniqueKey from '../../UniqueKeyGenerator/UniqueKeyGenerator';

import foodfightPrimaryLogo from '../../assets/foodfight_red_primary_05.png';

import '../../styles/header/Header.scss';
import { ProductTourContext } from '../../global/ProductTourContext';

/**
 * Component for AppTabs header
 * @param {Object} props
 * @param {React.Component} props.children - Component that should be in IonHeader but below logo and search bar
 * @param {boolean} props.hasSearchBar - Set to true if searchbar should be rendered, false to hide
 * @param {string} props.searchTerm - Current value in the searchbar
 * @param {Function} props.setSearchTerm - Callback function to call when searchbar value changes
 * @param {string} [props.placeholder] - Placeholder text for search bar
 * @param {number} props.debounce - Value for IonSearchbar debounce attribute
 * @param {("std")|IonButton[]} [props.btns] - Buttons to display on the right side of the header
 * @returns
 */

function Header({
  children,
  hasSearchBar = true,
  forwardRef,
  searchTerm,
  setSearchTerm,
  placeholder,
  debounce,
  btns: propBtns,
}) {
  const router = useIonRouter();

  const [btns, setBtns] = useState([]);

  const { visibility } = useContext(ProductTourContext);

  useEffect(() => {
    if (Array.isArray(propBtns)) {
      setBtns(
        propBtns.map(elem =>
          cloneElement(elem, {
            className: `${elem.props.className ?? ''}header-btn`,
            key: generateUniqueKey(),
          }),
        ),
      );
    }
  }, [propBtns]);

  const handleSearch = e => {
    if (typeof setSearchTerm === 'function') {
      setSearchTerm(e.detail.value || '');
    }
  };

  return (
    <IonHeader className="universal-header ion-no-border ion-no-padding">
      <IonToolbar className="search-wrap">
        <div className="icon-wrapper" slot="start">
          <IonImg src={foodfightPrimaryLogo} className="foodfight-icon" onClick={() => router.push('/app/home')} />
        </div>
        {hasSearchBar && (
          <IonSearchbar
            ref={forwardRef}
            value={searchTerm}
            onIonInput={handleSearch}
            placeholder={placeholder || 'Search...'}
            debounce={debounce}
            className={`header-search ${visibility.searchBarRef ? '' : 'hidden'}`}
          />
        )}
        <IonButtons className="header-buttons" slot="end">
          {btns}
        </IonButtons>
      </IonToolbar>
      {children}
    </IonHeader>
  );
}

export default Header;
